import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useTable, useSortBy, usePagination } from 'react-table';
import CompanyDataService from '../services/CompanyDataService';
import { 
    Table, TextField, TableBody, TableCell, TableHead, TableRow, 
    TableContainer, Paper, Box, Button, Typography, Select, MenuItem 
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { getUsers } from '../services/WordpressService';
import { format } from 'date-fns';
import { AUTH_TOKEN } from '../helper';


const CompaniesPendingReview = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState('Loading...');
    const [pageCount, setPageCount] = useState(0);
    const fetchIdRef = React.useRef(0);
    const pageSize = 50;
    const [users, setUsers] = useState([]);
    const [noDataMessage, setNoDataMessage] = useState('');
    const [filterName, setFilterName] = useState('');
    const [filterCountry, setFilterCountry] = useState('');
    useEffect(() => {
        const checkUserValidity = async () => {
          try {
            const userList = await getUsers(); // Make sure this is correctly implemented
            console.debug("Users:", userList);
            // No need to do anything if the user list is valid
          } catch (error) {
            console.error("Failed to fetch users:", error);
            localStorage.removeItem(AUTH_TOKEN);
            // Redirect using traditional window.location to avoid issues outside Router context
            window.location.href = '/login';
          }
        };
      
        // Only run this check if there's a user token present
        if (localStorage.getItem(AUTH_TOKEN)) {
          checkUserValidity();
        }
      }, []);
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const userList = await getUsers();
                setUsers(userList);
            } catch (error) {
                console.error("Failed to fetch users:", error);
            }
        };
        fetchUsers();
    }, []);

    const columns = React.useMemo(() => [
        {
            Header: 'Name',
            accessor: 'Company',
            Cell: ({ value, row }) => <Link to={`/company/${row.original.DraftID}/edit`}>{value}</Link>
        },
        {
            Header: 'Assigned Researcher',
            accessor: 'Researcher',
        },
        {
            Header: 'Updated Date',
            accessor: 'LastUpdated',
            Cell: ({ value }) => {
                if (!value) return null; // If there's no value, return null or a placeholder
                const date = new Date(value * 1000); // Convert the Unix timestamp to milliseconds and then to a Date object
                return format(date, 'EEE, MM/dd/yyyy - h:mmaaa'); // Format the date
            },
        },        
        {
            Header: 'Operations',
            accessor: 'DraftID',
            Cell: ({ value }) => <Link to={`/company/${value}/review`}>Review Changes</Link>
        }
    ], [users]);

    const mapResearcherIdToName = (researcherId, usersArray) => {
        console.debug("Researcher ID:", researcherId);
        console.debug("Users:", usersArray);
        const user = usersArray.find(user => user.userId === researcherId);
        console.debug("User:", user);
        return user ? user.username : '';
    };

    async function fetchData(params) {
        const { pageSize, pageIndex, sortBy, filterName, filterCountry} = params;
    
        const fetchId = ++fetchIdRef.current;
        setLoading(true);
    
        if (fetchId === fetchIdRef.current) {
            try {
                // Proceed only if users data is available
                if (users && users.length > 0) {
                    const sortField = sortBy.length ? sortBy[0].id : 'LastUpdated';
                    const sortOrder = sortBy.length && sortBy[0].desc ? 'desc' : 'asc';
                    const queryParams = new URLSearchParams();
                    if (filterName) queryParams.append('filterName', filterName);
                    if (filterCountry) queryParams.append('filterCountry', filterCountry);
                    // append filterModeration with two values, Ready for Review and Suggested for Removal.
                    queryParams.append('filterModeration', 'Ready for Review,Suggested for Removal');
                    
    
                    const response = await CompanyDataService.getAll(pageSize, pageIndex, sortField, sortOrder, queryParams.toString());
                    if (response.data.results.length === 0) {
                        // Set the message if no data is returned
                        setNoDataMessage('No Companies Pending Review.');
                    }
                    const companies = response.data.results;
                    // Map researcher ID to name
                    const companiesWithResearcherName = companies.map(company => ({
                        ...company,
                        Researcher: mapResearcherIdToName(company.Researcher, users)
                    }));
    
                    // Set up lat/long display
                    const companiesWithFormattedLocation = companiesWithResearcherName.map(company => ({
                        ...company,
                        // Ensure Latitude and Longitude exist before substring
                        Location: company.Latitude && company.Longitude 
                            ? `POINT (${company.Latitude.substring(0, 11)}, ${company.Longitude.substring(0, 11)})` 
                            : ''
                    }));
                    setData(companiesWithFormattedLocation);
                    setPageCount(Math.ceil(response.data.totalCount / pageSize));
                } else {
                    // Optional: Fetch users here if they're not available
                    // ...fetchUsers logic...
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                // Handle error appropriately
            }
            setLoading(false);
        }
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount: controlledPageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize: currentPageSize, sortBy },
    } = useTable(
        {
            columns, 
            data, 
            initialState: { pageIndex: 0, sortBy: [{ id: 'LastUpdated', desc: true }], pageSize: 50 }, // Set default sort here
            manualPagination: true, 
            pageCount, 
            autoResetPage: false, 
            manualSortBy: true
        },
        useSortBy,
        usePagination
    );

    // Debounced fetchData function
    const debouncedFetchData = useCallback(_.debounce(fetchData, 500), [users]);

    useEffect(() => {
        // Load filter states from localStorage
        const savedFilters = localStorage.getItem('companiesPendingReviewFilters');
        if (savedFilters) {
            const {
                filterName,
                filterCountry
            } = JSON.parse(savedFilters);
    
            setFilterName(filterName);
            setFilterCountry(filterCountry);
        }
    }, []);
    
    useEffect(() => {
        // Save filter states to localStorage
        const filters = {
            filterName,
            filterCountry
        };
        localStorage.setItem('companiesPendingReviewFilters', JSON.stringify(filters));
    }, [filterName, filterCountry]);
    

    useEffect(() => {
        // Trigger fetch data with filters
        const params = {
            pageIndex,
            pageSize: currentPageSize,
            sortBy,
            filterName,
            filterCountry
        };
    
        debouncedFetchData(params);
    }, [debouncedFetchData, pageIndex, currentPageSize, sortBy, filterName, filterCountry]);
    
    useEffect(() => {
        // Cleanup function to reset pagination
        return () => {
            gotoPage(0); // Navigate to the first page
        };
    }, [gotoPage]);
    
    const resetFilters = () => {
        setFilterName('');
        setFilterCountry('');
    
        // Trigger data fetch with reset filters
        debouncedFetchData({
            pageIndex: 0,
            pageSize: currentPageSize,
            sortBy,
            filterName: '',
            filterCountry: ''
        });
    
        // Reset localStorage
        localStorage.setItem('companiesPendingReviewFilters', JSON.stringify({
            filterName: '',
            filterCountry: ''
        }));
    };
    // Render UI
    return (
        <div>
            {/* Filters */}
            <Box display="flex" gap={2} marginBottom={2}>
                <TextField
                    label="Name"
                    value={filterName}
                    onChange={e => setFilterName(e.target.value)}
                    variant="outlined"
                />
                <Select
                    value={filterCountry}
                    onChange={e => setFilterCountry(e.target.value)}
                    displayEmpty
                >
                    <MenuItem value="">Any Country</MenuItem>
                    {/* Add more country options as needed */}
                </Select>
                <Button variant="contained" color="primary" onClick={resetFilters}>
                    Reset Filters
                </Button>
            </Box>

            {/* Table */}
            <TableContainer component={Paper}>
                <Table {...getTableProps()}>
                    <TableHead>
                        {headerGroups.map(headerGroup => (
                            <TableRow {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        {column.isSorted ? (column.isSortedDesc ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />) : null}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={columns.length} align="center">
                                    Loading...
                                </TableCell>
                            </TableRow>
                        ) : page.length > 0 ? (
                            page.map(row => {
                                prepareRow(row);
                                return (
                                    <TableRow {...row.getRowProps()}>
                                        {row.cells.map(cell => (
                                            <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                                        ))}
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length} align="center">
                                    {noDataMessage}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box p={2} display="flex" justifyContent="center" alignItems="center">
                <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</Button>
                <Button onClick={() => previousPage()} disabled={!canPreviousPage}>{'<'}</Button>
                <Button onClick={() => nextPage()} disabled={!canNextPage}>{'>'}</Button>
                <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</Button>
                <Typography>
                    Page {pageIndex + 1} of {pageOptions.length}
                </Typography>
                <Select
                    value={currentPageSize}
                    onChange={e => {setPageSize(Number(e.target.value)); gotoPage(0);}}
                    displayEmpty
                >
                    {[50, 100, 250, 500].map(size => (
                        <MenuItem key={size} value={size}>
                            Show {size}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
        </div>
    );
};

export default CompaniesPendingReview;