import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { AUTH_TOKEN } from '../helper';

const ProtectedRoute = ({ children, allowedRoles }) => {
  const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
  console.debug(user);
  const userRoles = user?.user_roles ?? [];

  // Check if user has any of the allowed roles
  const hasRequiredRole = allowedRoles.some(role => userRoles.includes(role));

  if (!hasRequiredRole) {
    return <Navigate to="/login" /* ...other props... */ />;
  }

  return children;
};

    
export default ProtectedRoute;
