import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import config from '../config/app.json';
import { AUTH_TOKEN } from '../helper';

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [validate, setValidate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const handleLogin = (event) => {
    event.preventDefault();
    setLoading(true);
    setValidate(true);
    setError('');

    axios.post(config.domain.env.siteUrl + config.API.WP + config.API.JWT + config.endpoint.token, {
      username,
      password
    })
    .then(user => handleLoginSuccess(user.data))
    .catch(error => handleLoginFail(error));
  }

  const handleLoginSuccess = (user) => {
    localStorage.setItem(AUTH_TOKEN, JSON.stringify(user));
    setValidate(false);
    setLoading(false);
    setUsername('');
    setPassword('');
    setError('');
    navigate('/'); // Use navigate to redirect
    window.location.reload();
  }

  const handleLoginFail = (error) => {
    setValidate(true);
    setLoading(false);
    setError('Invalid username / password');
  }

  const renderError = () => {
    if (error) {
      return <Alert variant="danger">{error}</Alert>;
    } else if (loading) {
      return <Alert variant="primary">Loading...</Alert>;
    }
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'username') {
      setUsername(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };
  
  return (
    <div className="container">
      <Form 
        noValidate
        validated={validate}
        onSubmit={handleLogin}
      >
        <h2>Uniworld Online backend login</h2>
        <Form.Group controlId="username">
          <Form.Label>Username</Form.Label>
          <Form.Control 
            type="text" 
            required 
            placeholder="Username / Email"
            name="username" // Add a name attribute
            onChange={handleInputChange}
            value={username}
          />
          <Form.Control.Feedback type="invalid">Username cannot be empty!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control 
            type="password" 
            required 
            placeholder="Password"
            name="password" // Add a name attribute
            onChange={handleInputChange}
            value={password}
          />
          <Form.Control.Feedback type="invalid">Password cannot be empty!</Form.Control.Feedback>
        </Form.Group>
        {renderError()} {/* Call the function to render error */}
        <Button variant="primary" type="submit">
          Login
        </Button>
      </Form>
    </div>
  );
}

export default LoginForm;
