import axios from 'axios';
import { AUTH_TOKEN } from '../helper';

// Load your configuration
const config = require('../config/app.json');

// Base URL for WordPress API
const BASE_URL = `${config.domain.env.siteUrl}${config.API.WP}`;

// Function to retrieve the JWT token from local storage
const getAuthToken = () => {
    const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
    const tokenString = user.token;
    const token = tokenString ? tokenString : null;
    return token ? token : null; // Adjust based on the structure of your stored token
};

const getUsers = async () => {
    const token = getAuthToken();
    console.debug("Token:", token);
    if (!token) {
        throw new Error("No authentication token found.");
    }

    try {
        const response = await axios.get(`${BASE_URL}wp/v2/users`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                roles: ['contributor', 'editor', 'administrator', 'researcher'],
                per_page: '100'
            }
        });
        console.debug("Response:", response);
        return response.data.map(user => ({
            username: user.name,
            userId: user.id,
            role: user.roles  // Assuming each user has one role
        }));
    } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
    }
};

export { getUsers };
