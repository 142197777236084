import React, { useRef, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CompanyDataService from '../services/CompanyDataService';
import { 
    Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, 
    TableRow, Paper, TablePagination, TextField, Typography, Alert, MenuItem,
    CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { subsidiaryTypes, listOfTitles, stateNameToCode } from '../helper';
import { AUTH_TOKEN } from '../helper';

const ResizableTableCell = styled(TableCell)(({ width }) => ({
    position: 'relative',
    width: width || 'auto',
    minWidth: width || 150,
    padding: '16px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}));

const ResizeHandle = styled('div')({
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    width: '4px',
    cursor: 'col-resize',
    backgroundColor: 'transparent',
    '&:hover': {
        backgroundColor: '#999'
    },
    '&.dragging': {
        backgroundColor: '#999',
        width: '2px'
    }
});

const StyledTableContainer = styled(TableContainer)({
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
    '& table': {
        tableLayout: 'fixed'
    }
});

const BulkImportSubsidiaries = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [subsidiaryData, setSubsidiaryData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [countryStatesMap, setCountryStatesMap] = useState({});
    const [invalidRows, setInvalidRows] = useState([]);
    const [resizing, setResizing] = useState(null);
    const [columnWidths, setColumnWidths] = useState({});
    const [initialWidths, setInitialWidths] = useState({});
    const tableRef = useRef(null);
    // Define the expected columns for the CSV
    const [processingAddresses, setProcessingAddresses] = useState(false);
    const [processedCount, setProcessedCount] = useState(0);
    const [totalToProcess, setTotalToProcess] = useState(0);
    const expectedColumns = [
        'Company', 'Address', 'Address2', 'City', 'StateProvince', 'PostalCode',
        'Country', 'Email', 'Telephone', 'Fax', 'Website', 'SubsidiaryType',
        'Contact1', 'Contact1Title', 'Contact2', 'Contact2Title', 'Latitude', 'Longitude'
    ];

    useEffect(() => {
        const fetchCountriesAndStates = async () => {
            try {
                const countriesResponse = await CompanyDataService.getAllCountries();
                setCountries(countriesResponse.data);

                const statesResponse = await CompanyDataService.getAllStates();
                setCountryStatesMap(statesResponse.data);
            } catch (error) {
                console.error("Failed to fetch countries and states:", error);
            }
        };

        fetchCountriesAndStates();
    }, []);

    const validateRow = (row) => {
        const errors = [];
    
        // Store original lat/long values for debugging
        const origLat = row.Latitude;
        const origLong = row.Longitude;
    
        // Validate Country
        if (row.Country && !countries.includes(row.Country)) {
            errors.push('Country');
        }
    
        // Validate StateProvince based on Country
        if (row.StateProvince && row.Country) {
            const validStates = countryStatesMap[row.Country] || [];
            if (validStates.length > 0 && !validStates.includes(row.StateProvince)) {
                errors.push('StateProvince');
            }
        }
    
        // Validate SubsidiaryType
        if (row.SubsidiaryType && !subsidiaryTypes.includes(row.SubsidiaryType)) {
            errors.push('SubsidiaryType');
        }
    
        // Validate Contact Titles only if they're non-empty
        if (row.Contact1Title && row.Contact1Title.trim() !== '') {
            if (!listOfTitles.includes(row.Contact1Title)) {
                errors.push('Contact1Title');
            }
        }
        
        if (row.Contact2Title && row.Contact2Title.trim() !== '') {
            if (!listOfTitles.includes(row.Contact2Title)) {
                errors.push('Contact2Title');
            }
        }
    
        // Log if geocoding data changed during validation
        if (origLat !== row.Latitude || origLong !== row.Longitude) {
            console.warn('Geocoding data changed during validation:', {
                rowIndex: row.rowIndex,
                original: { lat: origLat, long: origLong },
                current: { lat: row.Latitude, long: row.Longitude }
            });
        }
    
        return errors;
    };

    // Calculate initial column widths based on content
    const calculateColumnWidths = (data) => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      context.font = '14px Roboto'; // Match your table font

      const widths = {};
      expectedColumns.forEach(column => {
          // Start with header width
          let maxWidth = context.measureText(column).width + 40; // padding

          // Check all row values
          data.forEach(row => {
              const cellContent = row[column]?.toString() || '';
              const cellWidth = context.measureText(cellContent).width + 40;
              maxWidth = Math.max(maxWidth, cellWidth);
          });

          // Set minimum width
          widths[column] = Math.max(maxWidth, 150);
      });

      return widths;
  };

  useEffect(() => {
      if (subsidiaryData.length > 0) {
          const widths = calculateColumnWidths(subsidiaryData);
          setInitialWidths(widths);
          setColumnWidths(widths);
      }
  }, [subsidiaryData]);

  const startResizing = (e, column) => {
      e.preventDefault();
      const startX = e.pageX;
      const startWidth = columnWidths[column] || initialWidths[column] || 150;

      const handleMouseMove = (moveEvent) => {
          const diff = moveEvent.pageX - startX;
          const newWidth = Math.max(150, startWidth + diff);
          
          setColumnWidths(prev => ({
              ...prev,
              [column]: newWidth
          }));
      };

      const handleMouseUp = () => {
          document.removeEventListener('mousemove', handleMouseMove);
          document.removeEventListener('mouseup', handleMouseUp);
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
  };

  // Update the table header cell rendering
  const renderHeaderCell = (column) => (
      <ResizableTableCell
          key={column}
          width={columnWidths[column] || initialWidths[column]}
      >
          {column}
          <ResizeHandle
              onMouseDown={(e) => startResizing(e, column)}
          />
      </ResizableTableCell>
  );

  // Update the data cell rendering
  const renderDataCell = (row, column) => (
      <ResizableTableCell
          key={column}
          width={columnWidths[column] || initialWidths[column]}
          style={{
              backgroundColor: row.validationErrors?.includes(column) ? '#ffebee' : 'inherit'
          }}
      >
          <TextField
              value={row[column] || ''}
              onChange={(e) => handleCellChange(row.rowIndex, column, e.target.value)}
              variant="standard"
              size="small"
              fullWidth
              disabled={loading || column === 'Latitude' || column === 'Longitude'}
              error={row.validationErrors?.includes(column)}
              select={column === 'Country' || 
                     (column === 'StateProvince' && row.Country && countryStatesMap[row.Country]?.length > 0) ||
                     column === 'SubsidiaryType' ||
                     column === 'Contact1Title' ||
                     column === 'Contact2Title'}
          >
              {column === 'Country' && countries.map(country => (
                  <MenuItem key={country} value={country}>{country}</MenuItem>
              ))}
              {column === 'StateProvince' && row.Country && countryStatesMap[row.Country]?.map(state => (
                  <MenuItem key={state} value={state}>{state}</MenuItem>
              ))}
              {column === 'SubsidiaryType' && subsidiaryTypes.map(type => (
                  <MenuItem key={type} value={type}>{type}</MenuItem>
              ))}
              {(column === 'Contact1Title' || column === 'Contact2Title') && listOfTitles.map(title => (
                  <MenuItem key={title} value={title}>{title || '- None -'}</MenuItem>
              ))}
          </TextField>
      </ResizableTableCell>
    );

    const generateBlankCSV = () => {
        const csvContent = expectedColumns.join(',') + '\n';
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'subsidiary_import_template.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const processAddresses = async (data) => {
        setProcessingAddresses(true);
        const usRows = data.filter(row => row.Country === 'United States');
        setTotalToProcess(data.length);
        setProcessedCount(0);

        const updatedData = [...data];
        
        for (let i = 0; i < updatedData.length; i++) {
            const row = updatedData[i];
            
            // Store original geocoding data
            const originalLat = row.Latitude;
            const originalLong = row.Longitude;
            
            // ZIP+4 lookup for US addresses
            if (row.Country === 'United States') {
                try {
                    const stateCode = stateNameToCode[row.StateProvince] || row.StateProvince;
                    const address = {
                        street1: row.Address,
                        street2: row.Address2,
                        city: row.City,
                        state: stateCode,
                        zip: row.PostalCode ? row.PostalCode.split('-')[0] : ''
                    };

                    const zipResponse = await CompanyDataService.lookupZipPlus4(address);
                    if (!zipResponse.data.error && zipResponse.data.address) {
                        updatedData[i].PostalCode = zipResponse.data.address.ZIPCode + 
                            (zipResponse.data.address.ZIPPlus4 ? '-' + zipResponse.data.address.ZIPPlus4 : '');
                    }
                } catch (error) {
                    console.error('ZIP+4 lookup failed for row:', i, error);
                }
            }

            // Only geocode if we have enough address information
            if (row.Address && row.City && (!row.Latitude || !row.Longitude)) {
                try {
                    await new Promise(resolve => setTimeout(resolve, 20)); // Delay to avoid rate limits
                    
                    const fullAddress = `${row.Address} ${row.Address2 || ''} ${row.City} ${row.StateProvince || ''} ${row.PostalCode || ''} ${row.Country}`;
                    
                    const geocoder = new window.google.maps.Geocoder();
                    
                    const result = await new Promise((resolve, reject) => {
                        geocoder.geocode({ address: fullAddress }, (results, status) => {
                            if (status === 'OK' && results && results[0]) {
                                resolve(results[0].geometry.location);
                            } else {
                                reject(new Error(`Geocoding failed with status: ${status}`));
                            }
                        });
                    });

                    updatedData[i].Latitude = result.lat().toString();
                    updatedData[i].Longitude = result.lng().toString();
                    
                    console.log('Geocoding updated:', {
                        row: i,
                        address: fullAddress,
                        before: { lat: originalLat, long: originalLong },
                        after: { lat: updatedData[i].Latitude, long: updatedData[i].Longitude }
                    });
                } catch (error) {
                    console.error('Geocoding failed for row:', i, error);
                }
            }
            
            setProcessedCount(i + 1);
        }

        setSubsidiaryData(updatedData);
        setProcessingAddresses(false);
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const text = e.target.result;
                const lines = text.split('\n');
                const headers = lines[0].trim().split(',');

                // Validate headers
                const missingColumns = expectedColumns
                    .filter(col => !headers.includes(col))
                    .filter(col => col !== 'Latitude' && col !== 'Longitude'); // Don't require Lat/Long in CSV

                if (missingColumns.length > 0) {
                    setError(`Missing required columns: ${missingColumns.join(', ')}`);
                    return;
                }

                const parseCSVLine = (line) => {
                    const result = [];
                    let current = '';
                    let inQuotes = false;
                
                    for (let i = 0; i < line.length; i++) {
                        const char = line[i];
                        if (char === '"') {
                            if (inQuotes && line[i + 1] === '"') {
                                // Handle escaped quotes
                                current += char;
                                i++;
                            } else {
                                inQuotes = !inQuotes;
                            }
                        } else if (char === ',' && !inQuotes) {
                            result.push(current.trim());
                            current = '';
                        } else {
                            current += char;
                        }
                    }
                    result.push(current.trim());
                    return result;
                };
                
                const data = lines.slice(1)
                    .filter(line => line.trim())
                    .map((line, index) => {
                        const values = parseCSVLine(line);
                        const row = {};
                        headers.forEach((header, i) => {
                            row[header] = values[i] || '';
                        });
                        row.rowIndex = index + 1;
                        row.validationErrors = validateRow(row);
                        return row;
                    });

                setSubsidiaryData(data);
                
                // Process addresses after loading the data
                await processAddresses(data);
                
                const invalid = data.filter(row => row.validationErrors.length > 0)
                    .map(row => ({
                        company: row.Company,
                        errors: row.validationErrors
                    }));
                setInvalidRows(invalid);
                setError(invalid.length > 0 ? 'Some rows contain invalid data. Please check highlighted cells.' : '');
            };
            reader.readAsText(file);
        }
    };

    const handleCellChange = (rowIndex, column, value) => {
        console.log('Before update - Row:', subsidiaryData.find(row => row.rowIndex === rowIndex));
        
        const updatedData = subsidiaryData.map(row => {
            if (row.rowIndex === rowIndex) {
                // Keep all existing data and only update the changed column
                const updatedRow = {
                    ...row,
                    [column]: value
                };
                
                // Ensure geocoding data is preserved
                if (row.Latitude) updatedRow.Latitude = row.Latitude;
                if (row.Longitude) updatedRow.Longitude = row.Longitude;
                
                // Run validation but don't modify other fields
                updatedRow.validationErrors = validateRow(updatedRow);
                
                console.log('After update - Row:', updatedRow);
                return updatedRow;
            }
            return row;
        });
    
        // Verify the update preserved geocoding
        const updatedRow = updatedData.find(row => row.rowIndex === rowIndex);
        console.log('Final updated row:', updatedRow);
        
        setSubsidiaryData(updatedData);
    
        // Update invalid rows list without modifying the data
        const invalid = updatedData.filter(row => row.validationErrors.length > 0)
            .map(row => ({
                company: row.Company,
                errors: row.validationErrors
            }));
        setInvalidRows(invalid);
        setError(invalid.length > 0 ? 'Some rows contain invalid data. Please check highlighted cells.' : '');
    };

    const handleReset = () => {
        setSubsidiaryData([]);
        setError('');
        setSuccess('');
        setInvalidRows([]);
    };

    const handleSaveAll = async () => {
      if (invalidRows.length > 0) {
          setError('Cannot save while there are validation errors. Please correct the highlighted cells.');
          return;
      }
  
      setLoading(true);
      setError('');
      const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
      const timestamp = new Date().toISOString().split('T')[0];
  
      try {
          for (const subsidiary of subsidiaryData) {
              // Determine whether to use DraftParentID or ParentNID based on the ID value
              const parentIdField = parseInt(id) > 10000000 ? 'DraftParentID' : 'ParentNID';
              const submissionData = {
                  ...subsidiary,
                  [parentIdField]: id,  // Dynamically set either DraftParentID or ParentNID
                  Status: 'Draft',
                  History: `${timestamp} ${user.user_display_name} created subsidiary via bulk import`,
              };
  
              try {
                  await CompanyDataService.create(submissionData);
              } catch (err) {
                  setError(`Failed to save subsidiary at row ${subsidiary.rowIndex}: ${err.message}`);
                  setLoading(false);
                  return;
              }
          }
  
          setSuccess('All subsidiaries have been imported successfully');
          setLoading(false);
          setTimeout(() => {
              navigate(`/company/${id}/subsidiaries`);
          }, 2000);
      } catch (err) {
          setError('An unexpected error occurred during import');
          setLoading(false);
      }
  };

    return (
    <Wrapper apiKey={"AIzaSyBzCC2DktRuOoBtvHD3w1p8wIygDHTVyBY"} >
      <Box p={3}>
          <Typography variant="h4" gutterBottom>
              Bulk Import Subsidiaries
          </Typography>

          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
          {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
          {processingAddresses && (
            <Alert severity="info" sx={{ mb: 2 }}>
                Processing addresses: {processedCount} of {totalToProcess}
                <CircularProgress size={20} sx={{ ml: 2 }} />
            </Alert>
          )}
          
          {invalidRows.length > 0 && (
              <Alert severity="warning" sx={{ mb: 2 }}>
                  Invalid data in the following rows:
                  <ul>
                      {invalidRows.map((row, index) => (
                          <li key={index}>
                              {row.company}: Invalid {row.errors.join(', ')}
                          </li>
                      ))}
                  </ul>
              </Alert>
          )}
  
          <Box mb={2} display="flex" gap={2}>
              <Button
                  variant="contained"
                  component="label"
                  disabled={loading}
              >
                  Upload CSV File
                  <input
                      type="file"
                      hidden
                      accept=".csv"
                      onChange={handleFileUpload}
                  />
              </Button>
              <Button
                  variant="outlined"
                  onClick={generateBlankCSV}
                  disabled={loading}
              >
                  Download Blank Template
              </Button>
          </Box>
  
          {subsidiaryData.length > 0 && (
              <>
                  <Box mb={2} display="flex" gap={2}>
                      <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSaveAll}
                          disabled={loading || invalidRows.length > 0}
                      >
                          {loading ? 'Saving...' : 'Save All Subsidiaries as Draft'}
                      </Button>
                      <Button
                          variant="outlined"
                          color="secondary"
                          onClick={handleReset}
                          disabled={loading}
                      >
                          Reset Values
                      </Button>
                  </Box>
  
                  <StyledTableContainer component={Paper} ref={tableRef}>
                      <Table stickyHeader>
                          <TableHead>
                              <TableRow>
                                  <TableCell width={70}>Row</TableCell>
                                  {expectedColumns.map(column => renderHeaderCell(column))}
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              {subsidiaryData
                                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                  .map((row) => (
                                      <TableRow key={row.rowIndex}>
                                          <TableCell width={70}>{row.rowIndex}</TableCell>
                                          {expectedColumns.map(column => renderDataCell(row, column))}
                                      </TableRow>
                                  ))}
                          </TableBody>
                      </Table>
                  </StyledTableContainer>
  
                  <TablePagination
                      component="div"
                      count={subsidiaryData.length}
                      page={page}
                      onPageChange={(event, newPage) => setPage(newPage)}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={(event) => {
                          setRowsPerPage(parseInt(event.target.value, 10));
                          setPage(0);
                      }}
                      rowsPerPageOptions={[10, 50, 100, 200]}
                  />
              </>
          )}
      </Box>
    </Wrapper>
  );
};

export default BulkImportSubsidiaries;