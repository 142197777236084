export const userReducer = (state = {}, action) => {
    switch (action.type) {
        case 'USER_LOGIN':
            // handle login
            return { ...state, /* new state */ };
        // other cases
        default:
            return state;
    }
};
