import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import { useTable, useSortBy, usePagination } from 'react-table';
import CompanyDataService from '../services/CompanyDataService';
import { 
    Table, TextField, Slider, TableBody, TableCell, TableHead, TableRow,
    TableContainer, Paper, Box, Button, Typography, Select, MenuItem, Menu,
    Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { getUsers } from '../services/WordpressService'; // Adjust the path as needed
import { format } from 'date-fns';
import { AUTH_TOKEN } from '../helper';
import AssignResearcher from '../components/AssignResearcher';
import { hi } from 'date-fns/locale';


const CompaniesList = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const fetchIdRef = React.useRef(0);
    const pageSize = 50; // Number of records per page
    const [users, setUsers] = useState([]);
    const [filterName, setFilterName] = useState('');
    const [filterCountry, setFilterCountry] = useState('');
    const [filterModeration, setFilterModeration] = useState('');
    const [filterEmployees, setFilterEmployees] = useState([0, 3000000]); // [min, max]
    const [filterRevenue, setFilterRevenue] = useState([0, 1000000]); // [min, max]
    const [filterState, setFilterState] = useState('');
    const [stateOptions, setStateOptions] = useState([]);
    const [employeesFrom, setEmployeesFrom] = useState(0);
    const [employeesTo, setEmployeesTo] = useState(3000000);
    const [revenueFrom, setRevenueFrom] = useState(0);
    const [revenueTo, setRevenueTo] = useState(1000000);
    const [filterResearcher, setFilterResearcher] = useState(''); // Add a state for researcher filter
    const location = useLocation(); // Get the current location
    const { user_id } = JSON.parse(localStorage.getItem(AUTH_TOKEN)) || {}; // Extract user_id from local storage
    const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
    const userRoles = user?.user_roles ?? [];
    const [noDataMessage, setNoDataMessage] = useState('');
    const [isAssignResearcherModalOpen, setIsAssignResearcherModalOpen] = useState(false);
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);
    const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
    const [selectedCopyCompanyId, setSelectedCopyCompanyId] = useState(null);
    const [numberOfCopies, setNumberOfCopies] = useState(1);
    const [countries, setCountries] = useState([]);
    const [hideModerationFilter, setHideModerationFilter] = useState(false);
    const defaultHiddenColumns = [];  // Example default hidden columns
    const allPossibleColumns = [
        { Header: 'Name', accessor: 'Company' },
        { Header: 'Country', accessor: 'Country' },
        { Header: 'City', accessor: 'City' },
        { Header: 'St./Prov.', accessor: 'StateProvince' },
        { Header: 'Address 1', accessor: 'Address'},
        { Header: 'Postal Code', accessor: 'PostalCode' },
        { Header: 'Employees', accessor: 'Employee' },
        { Header: 'Email', accessor: 'Email' },
        { Header: 'Sales', accessor: 'AnnualRevenue' },
        { Header: 'Researcher', accessor: 'Researcher' },
        { Header: 'Title', accessor: 'Contact1Title' },
        { Header: 'Published', accessor: 'published' },
        { Header: 'Status', accessor: 'Status' },
        { Header: 'Updated', accessor: 'LastUpdated'},
        { Header: 'Latitude', accessor: 'Latitude'},
        { Header: 'Longitude', accessor: 'Longitude'}
    ];
    const cellStyle = {
        padding: '10px',
        textAlign: 'left', // Adjust as needed
    };

    const [hiddenColumns, setHiddenColumns] = useState(() => {
        const savedHiddenColumns = localStorage.getItem('hiddenColumns');
        console.debug('hidden columns')
        console.debug(savedHiddenColumns)
        return savedHiddenColumns ? JSON.parse(savedHiddenColumns) : defaultHiddenColumns;
    });

    useEffect(() => {
        const checkUserValidity = async () => {
          try {
            const userList = await getUsers(); // Make sure this is correctly implemented
            console.debug("Users:", userList);
            // No need to do anything if the user list is valid
          } catch (error) {
            console.error("Failed to fetch users:", error);
            localStorage.removeItem(AUTH_TOKEN);
            // Redirect using traditional window.location to avoid issues outside Router context
            window.location.href = '/login';
          }
        };
      
        // Only run this check if there's a user token present
        if (localStorage.getItem(AUTH_TOKEN)) {
          checkUserValidity();
        }
      }, []);

    useEffect(() => {
        // Set the no data message based on the route
        if (location.pathname === '/') {
            setNoDataMessage('Loading...');
        } else if (location.pathname === '/companies') {
            setNoDataMessage('Loading...');
        }
    }, [location]);
    useEffect(() => {
        // Check if the route is the root route
        if (location.pathname === '/') {
            // Set the researcher filter to the user's user ID
            setFilterResearcher(user_id);
            if (!userRoles.includes("administrator")) {
                setHideModerationFilter(true);
            }
        } else {
            setHideModerationFilter(false);
        }
    }, [location, user_id]);

    const handleAssignResearcherClick = (companyId, companyRowInfo, event) => {
        event.preventDefault();
        setSelectedCompanyId(companyId);
        setIsAssignResearcherModalOpen(true);
    };
    const handleCopyClick = (companyId, event) => {
        event.preventDefault();
        setSelectedCopyCompanyId(companyId);
        setNumberOfCopies(1);
        setIsCopyModalOpen(true);
    };
    const handleDelete = async (nid) => {
        if (window.confirm("Are you sure you want to delete this company?")) {
            try {
                await CompanyDataService.delete(nid);
                // Refresh the list or page
                window.location.reload(); // Simple page reload
                // Alternatively, you can fetch the list again if you have a function to do so
            } catch (error) {
                // Handle error (e.g., show an error message)
                console.error("Error deleting company:", error);
            }
        }
    };
    
    const handleCopySubmit = async () => {
        setIsCopyModalOpen(false);
        try {
            const response = await CompanyDataService.get(selectedCopyCompanyId);
            const originalData = response.data;
            for (let i = 1; i <= numberOfCopies; i++) {
                let copyData = {
                    ...originalData.company,
                    NID: undefined,
                    DraftID: undefined,
                    Status: 'Draft',
                    Company: `Copy ${i} of ${originalData.company.Company}`
                };
                await CompanyDataService.create(copyData);
            }
    
            // Handle after successful copies (refresh list or show message)
        } catch (error) {
            // Handle error
        }
    };
    

    // Update slider from text field
    const handleEmployeesChange = (event, type) => {
        const value = Math.max(0, Math.min(3000000, Number(event.target.value)));
        if (type === 'from') setEmployeesFrom(value);
        else setEmployeesTo(value);
    };

    const handleRevenueChange = (event, type) => {
        const value = Math.max(0, Math.min(1000000, Number(event.target.value)));
        if (type === 'from') setRevenueFrom(value);
        else setRevenueTo(value);
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const userList = await getUsers();
                setUsers(userList);
                console.debug("Users:", userList);
            } catch (error) {
                console.error("Failed to fetch users:", error);
            }
        };
    
        fetchUsers();
    }, []);
    const handleCountryChange = async (e) => {
        const selectedCountry = e.target.value;
        setFilterCountry(selectedCountry);
        setFilterState(''); // Reset filterState whenever filterCountry changes
    
        if (selectedCountry) {
            try {
                const response = await CompanyDataService.getAllStates('parent', 'all');
                const statesForCountry = response.data[selectedCountry] || [];
                setStateOptions(statesForCountry);
            } catch (error) {
                console.error("Failed to fetch states:", error);
                setStateOptions([]); // Clear states on error
            }
        } else {
            setStateOptions([]); // Clear states when no country is selected
        }
    };
    const columns = React.useMemo(() => {
        const allColumns = [
            {
                Header: 'Name',
                accessor: 'Company',
                Cell: ({ row }) => (
                    <Link to={`/company/${row.original.DraftID || row.original.NID}/edit`}>
                        {row.original.Company}
                    </Link>
                ),
            },
            { Header: 'Country', accessor: 'Country' },
            { Header: 'City', accessor: 'City' },
            { Header: 'St./Prov.', accessor: 'StateProvince' },
            { Header: 'Address 1', accessor: 'Address' },
            { Header: 'Postal Code', accessor: 'PostalCode' },
            {
                Header: 'Employees',
                accessor: 'Employee',
                Cell: ({ value }) => {
                    if (!value || value == '0') {
                        return '';
                    }
                    else {
                        return value;
                    }
                }
            },
            { 
                Header: 'Sales',
                accessor: 'AnnualRevenue',
                Cell: ({ value }) => {
                    if (!value || value == '0') {
                        return '';
                    }
                    else {
                        return value;
                    }
                }
            },
            { Header: 'Researcher', accessor: 'Researcher' },
            {
                Header: 'Updated',
                accessor: 'LastUpdated',
                Cell: ({ value }) => {
                    if (!value) return null; // If there's no value, return null or a placeholder
                    const date = new Date(value * 1000); // Convert the Unix timestamp to milliseconds and then to a Date object
                    return format(date, 'EEE, MM/dd/yyyy - h:mmaaa'); // Format the date
                },
            },
            { Header: 'Status', accessor: 'Status' },
            {
                Header: 'Published',
                accessor: row => row.published, // Fetch data using a function
                id: 'published', // Unique ID for this column
                Cell: ({ value }) => {
                    if (value === 1) return 'Yes';
                    else return 'No';
                }
            },            
            {
                Header: 'Options',
                disableSortBy: true,
                id: 'options',
                Cell: ({ row }) => (
                    <>
                        <Link to={`/company/${row.original.DraftID || row.original.NID}/edit`}>Edit</Link>
                        {" | "}
                        <Link to={`/company/${row.original.NID || row.original.DraftID}/subsidiaries`}>Subsidiaries</Link>
                        {" | "}
                        <a href="javascript:void(0)" onClick={(e) => handleCopyClick(row.original.DraftID || row.original.NID, e)}>Copy</a>
                        {row.original.DraftID && (
                                    <>
                                    &nbsp;| <Link to={`/company/${row.original.DraftID || row.original.NID}/review`}>Review Changes</Link>
                                    </>
                                )}
                        {userRoles.includes("administrator") && (
                            <>
                                {" | "}
                                <a href="javascript:void(0)" onClick={(e) => handleAssignResearcherClick(row.original.DraftID || row.original.NID, row.original, e)}>Assign Researcher</a>
                                {" | "}
                                <a href="javascript:void(0)" onClick={() => handleDelete(row.original.DraftID || row.original.NID)}>Delete</a>
                            </>
                        )}
                        {row.original.NID && (
                            <>
                                &nbsp;| <Link to={`/company/${row.original.NID && row.original.DraftID ? row.original.DraftID : row.original.NID}/moderate`}>Moderate</Link>
                            </>
                        )}
                    </>
                ),
            },    
            {
                Header: 'Latitude',
                accessor: 'Latitude',
                Cell: ({ value }) => {
                    if (!value || value == '0') {
                        return '';
                    }
                    else {
                        return value.substring(0,11);
                    }
                }
            },
            {
                Header: 'Longitude',
                accessor: 'Longitude',
                Cell: ({ value }) => {
                    if (!value || value == '0') {
                        return '';
                    }
                    else {
                        return value.substring(0,11);
                    }
                }
            },
        ];
        console.debug(allColumns);
        console.debug(hiddenColumns);
        return allColumns.filter(column => !hiddenColumns.includes(column.accessor || column.id));
    }, [hiddenColumns]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount: controlledPageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize: currentPageSize, sortBy },
    } = useTable(
        {
            columns, 
            data, 
            initialState: { 
                pageIndex: 0,
                pageSize,
                sortBy: [
                    {
                        id: 'LastUpdated', // This should match the accessor of the column
                        desc: true // false for ascending, true for descending
                    }
                ],
                hiddenColumns: hiddenColumns // Use hiddenColumns state
            },
            manualPagination: true, 
            pageCount, 
            autoResetPage: false, 
            manualSortBy: true
        },
        useSortBy,
        usePagination
    );
    const toggleColumn = (accessor) => {
        const newHiddenColumns = hiddenColumns.includes(accessor)
            ? hiddenColumns.filter(id => id !== accessor)
            : [...hiddenColumns, accessor];
        setHiddenColumns(newHiddenColumns);
        localStorage.setItem('hiddenColumns', JSON.stringify(newHiddenColumns));
    };
    // Debounced fetchData function
    const debouncedFetchData = useCallback(_.debounce(fetchData, 500), [users]);

    useEffect(() => {
        // Load filter states from localStorage
        const savedFilters = localStorage.getItem('companyListFilters');
        console.debug('adding filters', savedFilters);
        if (savedFilters) {
            const {
                filterName,
                filterCountry,
                filterState,
                filterModeration,
                employeesFrom, // add these
                employeesTo,   // add these
                revenueFrom,   // add these
                revenueTo      // add these
            } = JSON.parse(savedFilters);
    
            setFilterName(filterName);
            setFilterCountry(filterCountry);
            setFilterState(filterState);
            setFilterModeration(filterModeration);
            setEmployeesFrom(employeesFrom); // set these
            setEmployeesTo(employeesTo);     // set these
            setRevenueFrom(revenueFrom);     // set these
            setRevenueTo(revenueTo);         // set these
        }
    }, []);
    
    useEffect(() => {
        // Save filter states to localStorage
        const filters = {
            filterName,
            filterCountry,
            filterState,
            filterModeration,
            employeesFrom, // add these
            employeesTo,   // add these
            revenueFrom,   // add these
            revenueTo      // add these
        };
        localStorage.setItem('companyListFilters', JSON.stringify(filters));
    }, [filterName, filterCountry, filterState, filterModeration, employeesFrom, employeesTo, revenueFrom, revenueTo]);
    

    useEffect(() => {
        // Trigger fetch data with filters
        const params = {
            pageIndex,
            pageSize: currentPageSize,
            sortBy,
            filterName,
            filterCountry,
            filterState,
            filterModeration,
            filterEmployees: `${employeesFrom}-${employeesTo}`,
            filterRevenue: `${revenueFrom}-${revenueTo}`
        };
    
        debouncedFetchData(params);
    }, [debouncedFetchData, pageIndex, currentPageSize, sortBy, filterName, filterCountry, filterState, filterModeration, employeesFrom, employeesTo, revenueFrom, revenueTo]);
    
    useEffect(() => {
        // Cleanup function to reset pagination
        return () => {
            gotoPage(0); // Navigate to the first page
        };
    }, [gotoPage]);

    const fetchStates = async () => {
        if (filterCountry) {
            try {
                const response = await CompanyDataService.getAllStates('parent', 'all');
                const statesForCountry = response.data[filterCountry] || [];
                setStateOptions(statesForCountry);
            } catch (error) {
                console.error("Failed to fetch states:", error);
                setStateOptions([]); // Clear states on error
            }
        } else {
            setStateOptions([]); // Clear states when no country is selected
        }
    };
    
    useEffect(() => {
        fetchStates();
    }, [filterCountry]); // Dependency on filterCountry and nid
    
    
    const resetFilters = () => {
        setFilterName('');
        setFilterCountry('');
        setFilterState('');
        setFilterModeration('');
        setEmployeesFrom(0);
        setEmployeesTo(3000000);
        setRevenueFrom(0);
        setRevenueTo(1000000);
    
        // Trigger data fetch with reset filters
        debouncedFetchData({
            pageIndex: 0,
            pageSize: currentPageSize,
            sortBy,
            filterName: '',
            filterCountry: '',
            filterState: '',
            filterModeration: '',
            employeesFrom: 0,
            employeesTo: 3000000,
            revenueFrom: 0,
            revenueTo: 1000000
        });
    
        // Reset localStorage
        localStorage.setItem('companyListFilters', JSON.stringify({
            filterName: '',
            filterCountry: '',
            filterState: '',
            filterModeration: '',
            employeesFrom: 0,
            employeesTo: 3000000,
            revenueFrom: 0,
            revenueTo: 1000000
        }));
    };
    
    // Define mapResearcherIdToName function here to ensure it uses the latest users data
    const mapResearcherIdToName = (researcherId, usersArray) => {
        const user = usersArray.find(user => user.userId === researcherId);
        return user ? user.username : '';
    };

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await CompanyDataService.getAllCountries('parents'); 
                setCountries(response.data);
            } catch (error) {
                console.error("Failed to fetch countries:", error);
            }
        };
    
        fetchCountries();
    }, []);
    
    async function fetchData(params) {
        const { pageSize, pageIndex, sortBy, filterName, filterCountry, filterState, filterModeration, filterEmployees, filterRevenue } = params;
    
        const fetchId = ++fetchIdRef.current;
        setLoading(true);
    
        if (fetchId === fetchIdRef.current) {
            try {
                // Proceed only if users data is available
                if (users && users.length > 0) {
                    const sortField = sortBy.length ? sortBy[0].id : '';
                    const sortOrder = sortBy.length && sortBy[0].desc ? 'desc' : 'asc';
                    const queryParams = new URLSearchParams();
                    if (filterName) queryParams.append('filterName', filterName);
                    if (filterCountry) queryParams.append('filterCountry', filterCountry);
                    if (filterCountry && filterState) queryParams.append('filterState', filterState);
                    if (filterModeration) queryParams.append('filterModeration', filterModeration);
                    if (filterEmployees) queryParams.append('filterEmployees', filterEmployees);
                    if (filterRevenue) queryParams.append('filterRevenue', filterRevenue);
                    if (filterResearcher) queryParams.append('filterResearcher', filterResearcher);
                    if (location.pathname === '/') {
                        queryParams.append('isAssignedCompanies', true);
                    }
                    const response = await CompanyDataService.getAll(pageSize, pageIndex, sortField, sortOrder, queryParams.toString());
                    if (response.data.results.length === 0) {
                        // Set the message if no data is returned
                        if (location.pathname === '/') {
                            setNoDataMessage('No Assigned Companies');
                        } else if (location.pathname === '/companies') {
                            setNoDataMessage('No Companies Found');
                        }                
                        setData([{ Company: noDataMessage }]); // You can adjust how you want to display this
                    }
                    const companies = response.data.results;
    
                    // Map researcher ID to name
                    const companiesWithResearcherName = companies.map(company => ({
                        ...company,
                        Researcher: mapResearcherIdToName(company.Researcher, users)
                    }));

                    setData(companiesWithResearcherName);
                    setPageCount(Math.ceil(response.data.totalCount / pageSize));
                } else {
                    // Optional: Fetch users here if they're not available
                    // ...fetchUsers logic...
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                // Handle error appropriately
            }
            setLoading(false);
        }
    }
    const CompaniesTableContainer = styled(TableContainer)({
        overflowX: 'visible', // Enable vertical scrolling
        width: 'fit-content', // Adjust as needed
    });
    const StickyTableHead = styled(TableHead)({
        position: 'sticky',
        top: 0,
        zIndex: 1, // Ensure the sticky header appears above other elements
        backgroundColor: 'white', // Optional: Customize the background color of the sticky header
    });

    // Render the UI for your table
    return (
        <div>
            <Dialog open={isAssignResearcherModalOpen} onClose={() => setIsAssignResearcherModalOpen(false)}>
                <DialogTitle>Assign Researcher
                    <IconButton onClick={() => setIsAssignResearcherModalOpen(false)} style={{ position: 'absolute', right: '8px', top: '8px' }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {selectedCompanyId && <AssignResearcher 
                        companyId={selectedCompanyId} 
                        onClose={() => setIsAssignResearcherModalOpen(false)} 
                    />}
                </DialogContent>
            </Dialog>
            <Dialog open={isCopyModalOpen} onClose={() => setIsCopyModalOpen(false)}>
                <DialogTitle>Copy Company
                    <IconButton onClick={() => setIsCopyModalOpen(false)} style={{ position: 'absolute', right: '8px', top: '8px' }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent><br />
                    <TextField
                        label="Number of Copies to Create"
                        type="number"
                        InputProps={{ inputProps: { min: 1 } }}
                        value={numberOfCopies}
                        onChange={e => setNumberOfCopies(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsCopyModalOpen(false)}>Cancel</Button>
                    <Button onClick={handleCopySubmit}>Copy</Button>
                </DialogActions>
            </Dialog>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Toggle Columns</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {allPossibleColumns.map((column) => (
                        <div key={column.accessor || column.id} style={{ marginRight: 20 }}>
                            <input
                                type="checkbox"
                                checked={!hiddenColumns.includes(column.accessor || column.id)}
                                onChange={() => toggleColumn(column.accessor || column.id)}
                                style={{ marginRight: 5 }}
                            />
                            {column.Header}
                        </div>
                    ))}
                </AccordionDetails>
            </Accordion>
            <div>

                {/* Filters */}
                <label>Name:</label>
                <TextField label="Name" value={filterName} onChange={e => {setFilterName(e.target.value); gotoPage(0); }} />

                <label>Country:</label>
                {/* Country Filter */}
                <Select value={filterCountry} onChange={handleCountryChange}>
                    <MenuItem value="">Any</MenuItem>
                    {countries.map(country => (
                        <MenuItem key={country} value={country}>{country}</MenuItem>
                    ))}
                </Select>
                {/* State Filter */}
                {stateOptions.length > 0 && (
                    <>
                        <label>State:</label>
                        <Select value={filterState} onChange={e => setFilterState(e.target.value)}>
                            <MenuItem value="">Any</MenuItem>
                            {stateOptions.map(state => (
                                <MenuItem key={state} value={state}>{state}</MenuItem>
                            ))}
                        </Select>
                    </>
                )}

                {!hideModerationFilter && (
                    <>
                        <label>Moderation State:</label>
                        {/* Moderation State Filter */}
                        <Select value={filterModeration} onChange={e => {setFilterModeration(e.target.value); gotoPage(0); }}>
                            <MenuItem value="">Any</MenuItem>
                            <MenuItem value="Draft">Draft</MenuItem>
                            <MenuItem value="Published">Published</MenuItem>
                            <MenuItem value="Ready for Review">Ready for Review</MenuItem>
                            <MenuItem value="Suggested for Removal">Suggested for Removal</MenuItem>
                            <MenuItem value="Assigned">Assigned</MenuItem>
                        </Select>
                    </>
                )}


                <label># of Employees:</label>
                <TextField value={employeesFrom} onChange={(e) => {handleEmployeesChange(e, 'from'); gotoPage(0); }} />
                <TextField value={employeesTo} onChange={(e) => {handleEmployeesChange(e, 'to');gotoPage(0); }} />
                <Slider value={[employeesFrom, employeesTo]}
                        onChange={(e, newValue) => {
                            setEmployeesFrom(newValue[0]);
                            setEmployeesTo(newValue[1]);
                        }}
                        valueLabelDisplay="auto" max={3000000} step={100} />

                <label>Sales (Millions):</label>
                <TextField value={revenueFrom} onChange={(e) => {handleRevenueChange(e, 'from');gotoPage(0); }} />
                <TextField value={revenueTo} onChange={(e) => {handleRevenueChange(e, 'to');gotoPage(0); }} />
                <Slider value={[revenueFrom, revenueTo]}
                        onChange={(e, newValue) => {
                            setRevenueFrom(newValue[0]);
                            setRevenueTo(newValue[1]);
                        }}
                        valueLabelDisplay="auto" max={1000000} step={100} />

            </div>
            <Button onClick={resetFilters}>Reset Filters</Button>
            <CompaniesTableContainer component={Paper}>
                <Table {...getTableProps()} key={hiddenColumns.join(",")}>
                    <StickyTableHead>
                        {headerGroups.map(headerGroup => (
                            <TableRow {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <TableCell {...column.getHeaderProps(column.getSortByToggleProps())} 
                                    style={{ 
                                        textDecoration: column.canSort ? 'underline' : 'none',
                                        cursor: column.canSort ? 'pointer' : 'auto',
                                    }}>
                                    {column.render('Header')}
                                    {/* Add sorting indicators if needed */}
                                    <span>
                                        {column.isSorted ? (column.isSortedDesc ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />) : null}
                                    </span>
                                </TableCell>
                            ))}
                            </TableRow>
                        ))}
                    </StickyTableHead>
                    <TableBody {...getTableBodyProps()}>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={columns.length} align="center">
                                    Loading...
                                </TableCell>
                            </TableRow>
                        ) : page.length > 0 ? (
                            page.map(row => {
                                prepareRow(row);
                                return (
                                    <TableRow {...row.getRowProps()}>
                                        {row.cells.map(cell => (
                                            <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                                        ))}
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length} align="center">
                                    {noDataMessage}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {/* Pagination controls will go here */}
            </CompaniesTableContainer>
            <Box p={2} display="flex" justifyContent="center" alignItems="center">
                <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</Button>
                <Button onClick={() => previousPage()} disabled={!canPreviousPage}>{'<'}</Button>
                <Button onClick={() => nextPage()} disabled={!canNextPage}>{'>'}</Button>
                <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</Button>
                <Typography>
                    Page {pageIndex + 1} of {pageOptions.length}
                </Typography>
                <Select
                    value={currentPageSize}
                    onChange={e => {setPageSize(Number(e.target.value)); gotoPage(0);}}
                    displayEmpty
                >
                    {[50, 100, 200, 300, 400, 500].map(size => (
                        <MenuItem key={size} value={size}>
                            Show {size}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
        </div>
    );
};

export default CompaniesList;
