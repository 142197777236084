import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import CompanyDataService from '../services/CompanyDataService';
import { format } from 'date-fns'; // Ensure you have date-fns installed for formatting dates
import { 
    Accordion, AccordionSummary, AccordionDetails, Button, Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SubsidiaryEdit from './SubsidiaryEdit';
import { AUTH_TOKEN } from '../helper';

const ModerateCompany = () => {
    const { id } = useParams();
    const [revisionList, setRevisionList] = useState([]);
    const [selectedRevisions, setSelectedRevisions] = useState({ left: null, right: null });
    const [openModal, setOpenModal] = useState(false);
    const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
    const userRoles = user?.user_roles ?? [];
    const [diffViewerTitles, setDiffViewerTitles] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedParentId, setSelectedParentId] = useState(null);
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);
    const [isEditSubsidiaryModalOpen, setIsEditSubsidiaryModalOpen] = useState(false);

    useEffect(() => {
        const fetchRevisionList = async () => {
            setIsLoading(true); // Start loading
            try {
                const response = await CompanyDataService.getVersionHistory(id);
                const data = response.data; // Adjust according to your response structure
                setRevisionList(data); // Adjust according to your response structure
                console.debug('response', response);
    
                // Auto-select the first two revisions for comparison, if available
                if (data.length > 1) {
                    if (data[0]['ID'] == null && data[0]['DraftID'] == null) {
                        // Set the History value for this element to the History value of the next element
                        data[0]['History'] = data[1]['History'];
                    }
                    if (data.length > 2) {
                        data.splice(1, 1);
                    } else {
                        if (data[0]['DraftID'] == null) {
                            data.shift();
                        }
                    }
                    let leftRevision = data[0];
                    let rightRevision = data[1] || data[0];
                    const getRevisionType = (revision, index) => {
                        if (index === 0 && 'DraftID' in revision) return 'draft';
                        if (index === 0 || (index === 1 && 'DraftID' in data[0])) return 'published';
                        return 'revision';
                    };
    
                    setSelectedRevisions({
                        left: { revisionData: leftRevision, revisionType: getRevisionType(leftRevision, 0) },
                        right: { revisionData: rightRevision, revisionType: getRevisionType(rightRevision, 1) },
                    });
                }
            } catch (error) {
                console.error('Error fetching version history:', error);
            } finally {
                setIsLoading(false); // Stop loading regardless of the outcome
            }
        };
    
        fetchRevisionList();
    }, [id]); 

    const handleRadioChange = (e, revision, side, index) => {
        const isDraft = 'DraftID' in revision && index === 0;
        const isPublished = index === 0 || (index === 1 && ('DraftID' in revisionList[0]));
        const revisionType = isDraft ? 'draft' : isPublished ? 'published' : 'revision';

        setSelectedRevisions(prev => ({
            ...prev,
            [side]: {
                revisionData: revision,
                revisionType,
            },
        }));
    };

    const getTitleValues = (id, draftid, sideTitle) => {
        if (id == null) {
            if (draftid != null) {
                sideTitle = `Current draft: ${draftid}`;
            }
            else {
                sideTitle = 'Current Published Revision';
            }
        }
        return sideTitle;
    }

    const handleCompareClick = () => {
        const leftId = revisionList.find(revision => revision === selectedRevisions.left.revisionData)?.ID;
        const leftDraftId = revisionList.find(revision => revision === selectedRevisions.left.revisionData)?.DraftID;
        const leftRevisionType = selectedRevisions.left.revisionType;
        const rightId = revisionList.find(revision => revision === selectedRevisions.right.revisionData)?.ID;
        const rightDraftId = revisionList.find(revision => revision === selectedRevisions.right.revisionData)?.DraftID;
        const rightRevisionType = selectedRevisions.right.revisionType;
        let rightTitle = `Revision ${leftId}`;
        let leftTitle = `Revision ${rightId}`;
        if (leftRevisionType === 'draft') {
            rightTitle = `Current draft: ${leftDraftId}`;
        }
        else if (leftRevisionType === 'published') {
            rightTitle = 'Current Published Revision';
        }
        if (rightRevisionType === 'draft') {
            leftTitle = `Current draft: ${rightDraftId}`;
        }
        else if (rightRevisionType === 'published') {
            leftTitle = 'Current Published Revision';
        }
        // console.debug('leftId', leftId, 'rightId', rightId);
        console.debug('left revisionType', selectedRevisions.left.revisionType, 'right revisionType', selectedRevisions.right.revisionType);

        setOpenModal(true);
        setDiffViewerTitles({ leftTitle, rightTitle });
    };

    const closeModal = () => {
        setOpenModal(false);
    };

    const generateDiffText = (previous, current) => {
        let previousText = '';
        let currentText = '';
        if (previous && current) {
            const allKeys = new Set([...Object.keys(previous || {}), ...Object.keys(current || {})]);
            // Remove Keys.
            allKeys.delete('DraftID');
            allKeys.delete('ID');
            allKeys.delete('IsForeign');
            allKeys.delete('IsDomestic');
            allKeys.delete('Researcher');
            allKeys.delete('LastUpdated');
            allKeys.delete('History');
            allKeys.forEach(key => {
                if (previous[key] === current[key]) {
                    return;
                }
                if ((previous[key] === null || previous[key] === undefined || previous[key] == '') && (current[key] === null || current[key] === undefined || current[key] == '')) { 
                    return;
                }
                previousText += `${key}: ${previous ? previous[key] : ''}\n`;
                currentText += `${key}: ${current ? current[key] : ''}\n`;
            });
        } else if (previous) {
            for (const key in previous) {
                if (key != 'Status') {
                    previousText += `${key}: ${previous[key]}\n`;
                    currentText += '\n'; // Add empty lines for alignment
                }
            }
        } else if (current) {
            for (const key in current) {
                if (key != 'Status') {
                    previousText += '\n'; // Add empty lines for alignment
                    currentText += `${key}: ${current[key]}\n`;
                }
            }
        }
        return { previousText, currentText };
    };
    
    function createMarkup(notes) {
        return { __html: notes.replace(/\n/g, '<br />') };
    }

    const renderDiff = (previous, current, { leftTitle, rightTitle }) => {
        if (!previous && !current) {
            return <p>No changes</p>;
        }

        const { previousText, currentText } = generateDiffText(previous, current);
        
        if (!previousText.trim()) {
            return <p>No changes</p>;
        }
        
        let historyText = '';
        const styles = {
            variables: {
              light: {
                addedBackground: '#ffeef0',
                addedColor: '#24292e',
                removedBackground: '#e6ffed',
                removedColor: '#24292e',
                wordAddedBackground: '#fdb8c0',
                wordRemovedBackground: '#acf2bd',
                addedGutterBackground: '#ffdce0',
                removedGutterBackground: '#cdffd8',
                gutterBackground: '#f7f7f7',
                gutterBackgroundDark: '#f3f1f1',
              },
            },
            // diffRemoved: {
            //     backgroundColor: "#ffdce"
            // },
            // diffAdded: {
            //     backgroundColor: "#ffdce"
            // },
            line: {
              padding: '10px 2px',
              '&:hover': {
                background: '#a26ea1',
              },
            },
          };
        if (previous.History) {
            historyText = createMarkup(previous.History);
        }
        let accordionBackground = { backgroundColor: ''};
 

        return (
            <>
                {historyText && (
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <h5>Work History</h5>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div dangerouslySetInnerHTML={historyText} />
                        </AccordionDetails>
                    </Accordion>
                )}
                <ReactDiffViewer
                    oldValue={currentText}
                    newValue={previousText}
                    splitView={true}
                    leftTitle={leftTitle || "Current"} // Use dynamic title or fallback
                    rightTitle={rightTitle || "Previous"} // Use dynamic title or fallback
                    styles={styles}
                    compareMethod={DiffMethod.TRIMMED_LINES}
                />
            </>
        );
    };
    
    // Add custom CSS for table styling
    const tableStyle = {
        width: 'calc(100% - 100px)', // 100% width - 50px margin on each side
        margin: '50px',
        borderCollapse: 'collapse',
    };

    const cellStyle = {
        padding: '10px',
        textAlign: 'left', // Adjust as needed
    };
    const cellStyleRadios = {
        padding: '25px 50px',
        textAlign: 'left', // Adjust as needed
    };
    const cellStyleCentered = {
        padding: '10px',
        textAlign: 'center', // Adjust as needed
    };
    const linkStyle = {
        fontSize: '14px',
    }
    const revisionStyle = {
        fontSize: '0.6rem',
    }
    const handleEditSubsidiaryClick = (companyId, parentId, event) => {
        event.preventDefault();
        setSelectedCompanyId(companyId);
        setSelectedParentId(parentId);
        setIsEditSubsidiaryModalOpen(true);
    };
    const handleSubsidiaryEditClose = () => {
        setIsEditSubsidiaryModalOpen(false);
    };

    // Function to determine row background color
    const getRowBackgroundColor = (index, revision) => {
        if (index === 0 && 'DraftID' in revision) return '#faafbe'; // Pink for draft
        if (index === 0 || (index === 1 && ('DraftID' in revisionList[0]))) return '#aaffaa'; // Green for first element or first non-draft
        return ''; // Default, no specific color
    };
    if (isLoading) {
        return <div>Loading...</div>; // Replace with your loading throbber or message
    }
    
    return (
        <div style={{ paddingBottom: '100px' }}>
            <h4>{ revisionList[0]['ParentNID'] > 0 ? (
                <>
                    Subsidiary: { revisionList[0]['Company'] }<br />
                    Parent Company: {revisionList[0]['ParentCompany']}<br />
                    <Link style={linkStyle} to={`/company/${revisionList[0]['ParentNID'] }/edit`}>Edit Parent Company</Link>
                    <br />
                    <Link style={linkStyle} to={`/company/${revisionList[0]['ParentNID'] }/moderate`}>View Parent Company Revisions</Link>
                    <br />
                    <Link style={linkStyle} to={`/company/${revisionList[0]['ParentNID'] }/subsidiaries`}>Return to Subsidiaries Page</Link>
                </>
            ) : (
                <>
                    Parent Company: { revisionList[0]['Company'] }<br />
                    <Link style={linkStyle} to={`/company/${revisionList[0]['DraftID'] || revisionList[0]['NID'] }/edit`}>Edit Company</Link>
                    <br />
                    <Link style={linkStyle} to={`/company/${revisionList[0]['NID'] }/subsidiaries`}>View Company Subsidiaries</Link>
                </>
            )}
            </h4>
            <table style={tableStyle}>
                <thead>
                    <tr>
                        <th style={cellStyle}>Revision</th>
                        <th style={cellStyle}>Title</th>
                        <th style={cellStyle}>Date</th>
                        { revisionList.length > 1 && (
                            <th style={cellStyleCentered}><Button variant="contained" color="primary" onClick={handleCompareClick}>Compare</Button></th>
                        )}
                        <th style={cellStyle}>Revision Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {revisionList.map((revision, index) => (
                        <tr key={index} style={{ backgroundColor: getRowBackgroundColor(index, revision), ...((index % 2 === 0 && index > 1) ? { backgroundColor: '#f2f2f2' } : {}) }}>
                            <td style={cellStyle}>{index === 0 && 'DraftID' in revision ? `Current draft: ${revision.DraftID}` : index === 0 || (index === 1 && ('DraftID' in revisionList[0])) ? 'Current Published Version' : `Version ID: ${revision.ID}`}</td>
                            <td style={cellStyle}>
                                {revision.Company}<br />
                                <div style={revisionStyle}>
                                    {revision.History && revision.History.split(/\n/g).map((line, index) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                    ))}
                                </div>
                            </td>
                            <td style={cellStyle}>{format(new Date(revision.LastUpdated * 1000), 'MM/dd/yyyy - HH:mm')}</td>
                            { revisionList.length > 1 && (
                                <td style={{...cellStyleRadios, display: 'flex', justifyContent: 'space-between'}}>
                                    <input
                                        type="radio"
                                        checked={selectedRevisions.left && selectedRevisions.left.revisionData === revision}
                                        name="compare-left"
                                        onChange={(e) => handleRadioChange(e, revision, 'left', index)}
                                        disabled={selectedRevisions.right && selectedRevisions.right.revisionData === revision}
                                    />
                                    <input
                                        type="radio"
                                        name="compare-right"
                                        checked={selectedRevisions.right && selectedRevisions.right.revisionData === revision}
                                        onChange={(e) => handleRadioChange(e, revision, 'right', index)}
                                        disabled={selectedRevisions.left && selectedRevisions.left.revisionData === revision}
                                    />


                                </td>
                            )}
                            <td style={cellStyle}>
                                {index === 0  && 'DraftID' in revision ? (
                                    <>
                                        {revision.ParentNID > 0 ? (
                                            <Button variant="contained" color="info" onClick={(e) => handleEditSubsidiaryClick(revision.DraftID, revision.ParentNID, e)}>Edit Subsidiary</Button>
                                        ) : (
                                            <Button href={(`/company/${revision.DraftID}/edit`)} variant="contained" color="info">Edit</Button>
                                        )}
                                        &nbsp;&nbsp;
                                        {userRoles.includes("administrator") && (
                                            <Button variant="contained" color="warning">Delete</Button>
                                        )}
                                    </>
                                ) : revisionList.length > 1 && index != 0 && revision.ID && revision.NID && userRoles.includes("administrator") && (
                                    <Button variant="contained" onClick={() => console.log('Revert logic here')}>Revert</Button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Dialog open={isEditSubsidiaryModalOpen} onClose={() => setIsEditSubsidiaryModalOpen(false)}>
                    <DialogTitle>Subsidiary
                        <IconButton onClick={() => setIsEditSubsidiaryModalOpen(false)} style={{ position: 'absolute', right: '8px', top: '8px' }}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {selectedParentId && <SubsidiaryEdit 
                            companyId={selectedCompanyId}
                            parentId={selectedParentId}
                            onClose={handleSubsidiaryEditClose} 
                        />}
                    </DialogContent>
                </Dialog>
            <Dialog
                open={openModal}
                onClose={closeModal}
                sx={{
                    '& .MuiDialog-paper': { // Targeting the Dialog's paper element
                        width: '80%', // Set width to 80% of the screen
                        maxWidth: 'none', // Override the default maxWidth
                    },
                }}>
                <DialogTitle>View Differences
                    <IconButton onClick={closeModal} style={{ position: 'absolute', right: '8px', top: '8px' }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {selectedRevisions.left && selectedRevisions.right && renderDiff(selectedRevisions.left.revisionData, selectedRevisions.right.revisionData, diffViewerTitles)}
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ModerateCompany;
