import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { userReducer } from './reducers/userReducer';

export const rootReducer = combineReducers({
    user: userReducer,
    // other reducers
});
// Import your reducers here
// import someReducer from './features/someReducer';

export const store = configureStore({
  reducer: {
    rootReducer,
    // Register reducers here
    // someFeature: someReducer,
  },
  // Redux Toolkit includes Redux Thunk by default, so you don't need to add it separately
});

export default store;
