import React, { useEffect, useState } from 'react';
import DiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
import { useParams, useNavigate, Link } from 'react-router-dom';
import CompanyDataService from '../services/CompanyDataService';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Pagination from '@mui/lab/Pagination';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import { AUTH_TOKEN } from '../helper';

const CompanyReviewDiff = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [diffData, setDiffData] = useState({});
    const [showRejectTextArea, setShowRejectTextArea] = useState(false);
    const [notesForResearcher, setNotesForResearcher] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
    const userRoles = user?.user_roles ?? [];
    const [totalPages, setTotalPages] = useState(0);
    
    useEffect(() => {
        const fetchDiffData = async () => {
            try {
                const response = await CompanyDataService.getDiffs(id, currentPage, pageSize);
                setDiffData(response.data.data); // Adjust according to your response structure
                setTotalPages(response.data.pagination.totalPages);
            } catch (error) {
                console.error('Error fetching diff data:', error);
            }
        };
        
        fetchDiffData();
    }, [id, currentPage, pageSize]);
    
    // Step 4: Handle Page Changes and Page Size Selection
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
        setCurrentPage(1); // Reset to first page with new page size
    };

    // Step 3: Create Pagination Controls
    const renderPaginationControls = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '20px 0' }}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    showFirstButton
                    showLastButton
                />

                <FormControl variant="outlined" style={{ width: 120 }}>
                    <InputLabel>Page Size</InputLabel>
                    <Select
                        label="Page Size"
                        value={pageSize}
                        onChange={handlePageSizeChange}
                    >
                        {[10, 25, 50, 100, 1500].map(size => (
                            <MenuItem key={size} value={size}>{size}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        );
    };

    const generateDiffText = (draft, published, isParent) => {
        let draftText = '';
        let publishedText = '';
        if (draft && published) {
            const allKeys = new Set([...Object.keys(draft || {}), ...Object.keys(published || {})]);
            // Remove Keys.
            allKeys.delete('DraftID');
            allKeys.delete('DraftParentID');
            allKeys.delete('Status');
            allKeys.delete('markedDone');
            allKeys.delete('suggestForRemoval');
            allKeys.delete('LastUpdated');
            allKeys.delete('Researcher');
            allKeys.delete('IsDomestic');
            allKeys.delete('IsForeign');
            allKeys.delete('CountryPrefix');
            allKeys.delete('History');
            if (!isParent) {
                allKeys.delete('LinkedIn');
                allKeys.delete('Facebook');
                allKeys.delete('Instagram');
                allKeys.delete('Twitter');
                allKeys.delete('YouTube');
                allKeys.delete('Tiktok');
                allKeys.delete('CareerPageLink');
                allKeys.delete('Founded');
                allKeys.delete('Employee');
                allKeys.delete('AnnualRevenue');
                allKeys.delete('CareerPosting');
                allKeys.delete('CompanyDescription');
                allKeys.delete('NAICS');
                allKeys.delete('CompanyType');
                allKeys.delete('ParentCompany');
            }
            allKeys.forEach(key => {
                if (key === 'Contact2') {
                    console.debug('Contact2', draft[key], published[key])
                }
                if (draft[key] === published[key]) {
                    if (key === 'Contact2') {
                        console.debug('contact was equal');
                    }
                    return;
                }
                if ((draft[key] === null || draft[key] === undefined || draft[key] === '') && (published[key] === null || published[key] === undefined || published[key] === '')) {
                    if (key === 'Contact2') {
                        console.debug('contact was not set somewhere');
                    }
                    return;
                }
                draftText += `${key}: ${draft ? draft[key] : ''}\n`;
                publishedText += `${key}: ${published ? published[key] : ''}\n`;
            });
        } else if (draft) {
            for (const key in draft) {
                if (key !== 'Status') {
                    draftText += `${key}: ${draft[key]}\n`;
                    publishedText += '\n'; // Add empty lines for alignment
                }
            }
        } else if (published) {
            for (const key in published) {
                if (key !== 'Status') {
                    draftText += '\n'; // Add empty lines for alignment
                    publishedText += `${key}: ${published[key]}\n`;
                }
            }
        }
        return { draftText, publishedText };
    };
    
    function createMarkup(notes) {
        return { __html: notes.replace(/\n/g, '<br />') };
    }

    const renderDiff = (draft, published, isRemoval, isParent) => {
        if (!draft && !published) {
            return <p>No changes</p>;
        }
    
        // Check if parent has been removed
        if (isParentRemoved(draft)) {
            return (
                <Accordion style={{ backgroundColor: 'lightcoral' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <h5>Parent Removed</h5>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>The parent of this company has been removed.</p>
                    </AccordionDetails>
                </Accordion>
            );
        }

        const { draftText, publishedText } = generateDiffText(draft, published, isParent);
        
        if (!draftText.trim() && !isRemoval) {
            return <p>No changes</p>;
        }
        
        let historyText = '';
        if (draft.History) {
            historyText = createMarkup(draft.History);
        }

        const styles = {
        variables: {
            light: {
            addedBackground: '#ffeef0',
            addedColor: '#24292e',
            removedBackground: '#e6ffed',
            removedColor: '#24292e',
            wordAddedBackground: '#fdb8c0',
            wordRemovedBackground: '#acf2bd',
            addedGutterBackground: '#ffdce0',
            removedGutterBackground: '#cdffd8',
            gutterBackground: '#f7f7f7',
            gutterBackgroundDark: '#f3f1f1',
            },
        },
        line: {
            padding: '10px 2px',
            '&:hover': {
            background: '#a26ea1',
            },
            ...(isRemoval ? { backgroundColor: 'red' } : {}),
        },
        label: isRemoval ? 'Marked for Removal' : '',
        };

        console.log(styles);

        let accordionBackground = { backgroundColor: ''};
        let isNew = false;
        if (draftText.trim() && !publishedText.trim()) {
            isNew = true;
            accordionBackground = { backgroundColor: 'lightgreen' };
        }
        if (isRemoval) {
            accordionBackground = { backgroundColor: 'lightcoral' };
        }

        return (
            <Accordion style={accordionBackground}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <h5>{isNew ? (isParent ? 'New Company' : (isRemoval ? 'Marked for Removal' : 'New Subsidiary')) : (isRemoval ? 'Marked for Removal' : 'Changes')}</h5>
                </AccordionSummary>
                <AccordionDetails>
                    {historyText && (
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <h5>Work History</h5>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div dangerouslySetInnerHTML={historyText} />
                            </AccordionDetails>
                        </Accordion>
                    )}
                    <DiffViewer
                        oldValue={publishedText}
                        newValue={draftText}
                        splitView={true}
                        leftTitle="Published"
                        rightTitle="Draft"
                        styles={styles}
                        compareMethod={DiffMethod.TRIMMED_LINES}
                    />
                </AccordionDetails>
            </Accordion>
        );
    };
    

    const isSuggestedForDeletion = (draft) => {
        return draft?.suggestForRemoval === 1;
    };

    const isParentRemoved = (draft) => {
        return draft && draft.ParentNID === -1;
    };
    

    const isModified = (draft, published) => {
        return draft && published && generateDiffText(draft, published, false).draftText.trim();
    };

    const isNew = (draft, published) => {
        return draft && !published;
    };

    const isNoChanges = (draft, published) => {
        if (!draft && published) {
            const { draftText } = generateDiffText(draft, published, false);
            return !draftText.trim(); // No changes if the generated diff text is empty
        }
        return false;
    };

    const renderSubsidiaries = () => {
        const suggestedForDeletion = [];
        const parentsRemoved = [];
        const modified = [];
        const newSubs = [];
        const noChanges = [];
    
        Object.entries(diffData.Subs || {}).forEach(([key, value]) => {
            if (isSuggestedForDeletion(value.Draft)) {
                console.debug('suggested for deletion');
                suggestedForDeletion.push(renderSubsidiary(key, value));
            } else if (isParentRemoved(value.Draft)) {
                console.debug('parent removed');
                parentsRemoved.push(renderSubsidiary(key, value));
            } else if (isNew(value.Draft, value.Published)) {
                console.debug('new');
                newSubs.push(renderSubsidiary(key, value));
            } else if (isModified(value.Draft, value.Published)) {
                console.debug('modified');
                modified.push(renderSubsidiary(key, value));
            } else if (isNoChanges(value.Draft, value.Published)) {
                console.debug('no changes');
                noChanges.push(renderSubsidiary(key, value));
            } else {
                noChanges.push(renderSubsidiary(key, value));
            }
        });
    
        return [...suggestedForDeletion, ...parentsRemoved, ...newSubs, ...modified, ...noChanges];
    };
    
    const renderSubsidiary = (key, value) => {
        return (
            <div key={key}>
                <h3>Subsidiary {value.Draft ? value.Draft.Company : value.Published.Company}</h3>
                <em>{value.Draft ? 
                    value.Draft.Address + ' ' + value.Draft.City + ' ' + value.Draft.Country
                     : 
                    value.Published.Address + ' ' + value.Published.City + ' ' + value.Published.Country}</em>
                {renderDiff(value.Draft, value.Published, value.Draft?.suggestForRemoval, false)}
            </div>
        );
    };

    const handleApproveAll = async () => {
        try {
            const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
            const username = user ? user.user_display_name : '';
            await CompanyDataService.approve(id, username);
            navigate('/companies-pending-review');
        } catch (error) {
            console.error('Error approving changes:', error);
            // Handle error, e.g., show an error message
        }
    };

    const handleShowReject = () => {
        setShowRejectTextArea(true);
    };

    const handleConfirmRejection = async () => {
        if (!notesForResearcher.trim()) {
            alert('Please enter notes for the researcher.'); // Replace with a more user-friendly message or validation
            return;
        }

        try {
            const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
            const username = user ? user.user_display_name : '';
            const timestamp = new Date().toISOString().split('T')[0];
            let rejectNote = `${timestamp} ${user.user_display_name} rejected the company.\n`;
            let notes = `${rejectNote}${notesForResearcher}`;
            await CompanyDataService.deny(id, { notes, username: username });
            navigate('/companies-pending-review');
        } catch (error) {
            console.error('Error rejecting changes:', error);
            // Handle error, e.g., show an error message
        }
    };

    const handleTextAreaChange = (event) => {
        setNotesForResearcher(event.target.value);
    };

    return (
        <div style={{ paddingBottom: '100px' }}>
            {diffData.Parent && (
                <div>
                    <h2>Parent Company {diffData.Parent.Draft ? diffData.Parent.Draft.Company : diffData.Parent.Published.Company}</h2>
                    {diffData.Parent && diffData.Parent.Draft && diffData.Parent.Draft.Status === 'Ready for Review' ? (
                        <div className="review links">
                            {userRoles.includes("administrator") && (
                                <div className="link">
                                    <Link to={`/companies-pending-review`}>Back to Companies Pending Review</Link>
                                </div>
                            )}
                            <div className="link">
                                <Link to={`/company/${id}/edit`}>Edit Company</Link>
                            </div>
                            <div className="link">
                                <Link to={`/company/${diffData.Parent.Draft.NID || diffData.Parent.Draft.DraftID}/subsidiaries`}>Company Subsidiaries</Link>
                            </div>
                        </div>
                    ) : (
                        <div className="review links">
                            <div className="link">
                                <Link to={`/companies`}>Back to Companies List</Link>
                            </div>
                            <div className="link">
                                <Link to={`/company/${id}/edit`}>Edit Company</Link>
                            </div>
                            <div className="link">
                                <Link to={`/company/${diffData.Parent.Draft.NID || diffData.Parent.Draft.DraftID || id}/subsidiaries`}>Company Subsidiaries</Link>
                            </div>
                        </div>
                    )}
                    {renderDiff(diffData.Parent.Draft, diffData.Parent.Published, diffData.Parent.Draft?.suggestForRemoval, true)}
                </div>
            )}
            {renderSubsidiaries()}
            {userRoles.includes("administrator") && diffData.Parent && diffData.Parent.Draft && (
                <>
                    <Button variant="contained" color="primary" onClick={handleApproveAll}>
                        Approve All Changes
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleShowReject}>
                        Reject Changes and Send Back to Researcher
                    </Button>
                </>
            )}

            {showRejectTextArea && (
                <div>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        value={notesForResearcher}
                        onChange={handleTextAreaChange}
                        placeholder="Notes for researcher"
                        required
                    />
                    <Button variant="contained" color="primary" onClick={handleConfirmRejection}>
                        Confirm Rejection
                    </Button>
                </div>
            )}
            {renderPaginationControls()}
        </div>
    );
};

export default CompanyReviewDiff;
