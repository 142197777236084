import React, { useEffect, useState, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { TableSortLabel, Button, TextField, Select, MenuItem, InputLabel, FormControl, Chip, OutlinedInput,
    Dialog, DialogTitle, IconButton, DialogContent, CircularProgress, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import CloseIcon from '@mui/icons-material/Close';
import { debounce } from 'lodash';
// Import `useTheme` for styling multi-select
import { useTheme } from '@mui/material/styles';

// Import your data service and user fetching service
import { AUTH_TOKEN } from '../helper';
import { getUsers } from '../services/WordpressService'; // Adjust the path as needed
import CompanyDataService from '../services/CompanyDataService'; // Adjust the path as needed
import SubsidiaryEdit from './SubsidiaryEdit';

const ResearcherActivityReport = () => {
    const [activities, setActivities] = useState([]);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(1000);
    const [selectedParentId, setSelectedParentId] = useState(null);
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);
    const [companyNameInput, setCompanyNameInput] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [isEditSubsidiaryModalOpen, setIsEditSubsidiaryModalOpen] = useState(false);
    const actions = ['Saved', 'Assigned', 'Deleted', 'Published', 'Created new parent company',
        'Created new subsidiary company', 'Marked as done', 'Marked as not done', 'Suggested for removal',
        'Submitted for approval', 'Suggested to not be removed'];
    const theme = useTheme();

    const [filter, setFilter] = useState({
        companyType: '',
        action: [],
        companyName: '',
        researcher: '',
      });
    const [sort, setSort] = useState({ field: 'Timestamp', order: 'DESC' });
    // Fetch users on component mount
    useEffect(() => {
        const fetchUsersAndActivities = async () => {
            setLoading(true);
            try {
            const userList = await getUsers();
            setUsers(userList);
            } catch (error) {
            console.error("Failed to fetch users:", error);
            } finally {
            // Initial fetch for activities
            debouncedFetchActivities();
            }
        };        
        fetchUsersAndActivities();
    }, [filter, sort]);
    const handleSortChange = (sortInfo) => {
        const field = sortInfo.field;
        const order = sortInfo.order;
        setSort({
            field,
            order,
        });
    };
    const clearFilters = () => {
        setFilter({
          companyType: '',
          action: [],
          companyName: '',
          researcher: '',
        });
        setCompanyNameInput(""); // Reset immediate input state
        setStartDate(null);
        setEndDate(null);
        setPage(0);
        debouncedFetchActivities();
    };
    
      
      
    const handleEditSubsidiaryClick = (companyId, parentId, event) => {
        event.preventDefault();
        setSelectedCompanyId(companyId);
        setSelectedParentId(parentId);
        setIsEditSubsidiaryModalOpen(true);
    };
    const handleSubsidiaryEditClose = () => {
        setIsEditSubsidiaryModalOpen(false);
    };
    const handleCompanyNameChange = useCallback(debounce((value) => {
        setFilter((prev) => ({ ...prev, companyName: value }));
    }, 1000), []);
      
    // Define your fetchActivities function
    const fetchActivities = async () => {
        try {
          const { companyType, action, companyName, researcher } = filter;
          const startTimestamp = startDate ? startDate.getTime() / 1000 : '';
          const endTimestamp = endDate ? endDate.getTime() / 1000 : '';
          const response = await CompanyDataService.getResearcherActivityReport(
            rowsPerPage, page, action.join(','), researcher, companyType, companyName, startTimestamp, endTimestamp, sort.field, sort.order
          );
          setActivities(response.data.results);
          setTotalCount(response.data.totalCount);
        } catch (error) {
          console.error("Failed to fetch activities:", error);
        } finally {
          setLoading(false);
        }
      };
      

    // Debounce fetchActivities
    const debouncedFetchActivities = useCallback(debounce(fetchActivities, 500), [rowsPerPage, page, filter, sort, startDate, endDate]);

    // Use effect for re-fetching activities when dependencies change
    useEffect(() => {
        if (!loading) {
            debouncedFetchActivities();
        }
      }, [debouncedFetchActivities, filter, sort]);
      

    const handleChangePage = (event, newPage) => {
    setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    };

    function getStyles(name, personName, theme) {
        return {
          fontWeight:
            personName.indexOf(name) === -1
              ? theme.typography.fontWeightRegular
              : theme.typography.fontWeightMedium,
        };
    }
      
    const handleDownload = async () => {
        const { companyType, action, companyName, researcher } = filter;
        const startTimestamp = startDate ? startDate.getTime() / 1000 : '';
        const endTimestamp = endDate ? endDate.getTime() / 1000 : '';
        const userJson = encodeURIComponent(JSON.stringify(users));
        try {
            const downloadCsv = await CompanyDataService.getResearcherActivityReport(
                rowsPerPage, page, action.join(','), researcher, companyType, companyName, startTimestamp, endTimestamp, sort.field, sort.order, true, userJson
            );
            if (downloadCsv.data && downloadCsv.data.url) {
                // If the URL exists, create a link and trigger the download
                const link = document.createElement('a');
                link.href = downloadCsv.data.url;
                link.setAttribute('download', 'filename.csv'); // Optional: Specify a filename here
                document.body.appendChild(link);
                link.click();
                link.remove();
            } else {
                // If the URL does not exist, throw an error to be caught by the catch block
                throw new Error('Download URL is missing.');
            }
        } catch (error) {
            console.error("Failed to initiate download:", error);
            // Show an error message to the user. The method to do this depends on your UI framework
            // For example, if using React with a toast notification system, you might call:
            // toast.error("Failed to initiate download. Please try again.");
        }
    };
    
    

    return (
    <Paper>
        <FormControl style={{ margin: 8, minWidth: 160 }}>
            <InputLabel id="company-type-select-label">Company Type</InputLabel>
            <Select
            labelId="company-type-select-label"
            id="company-type-select"
            value={filter.companyType}
            onChange={(e) => setFilter({ ...filter, companyType: e.target.value })}
            label="Company Type"
            >
            <MenuItem value={'Parent'}>Parent</MenuItem>
            <MenuItem value={'Sub'}>Sub</MenuItem>
            </Select>
        </FormControl>

        <FormControl style={{ margin: 8, minWidth: 120 }}>
            <InputLabel id="action-select-label">Action</InputLabel>
            <Select
            labelId="action-select-label"
            id="action-select"
            multiple
            value={filter.action}
            onChange={(e) => setFilter({ ...filter, action: e.target.value })}
            input={<OutlinedInput id="select-multiple-chip" label="Action" />}
            renderValue={(selected) => (
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {selected.map((value) => (
                    <Chip key={value} label={value} style={{ margin: 2 }} />
                ))}
                </div>
            )}
            MenuProps={{
                PaperProps: {
                style: {
                    maxHeight: 224,
                    width: 250,
                },
                },
            }}
            >
            {actions.map((action) => (
                <MenuItem key={action} value={action} style={getStyles(action, filter.action, theme)}>
                {action}
                </MenuItem>
            ))}
            </Select>
        </FormControl>

        <TextField
            id="company-name-input"
            label="Company Name"
            type="text"
            variant="outlined"
            value={companyNameInput} // Use the new state here
            onChange={(e) => {
                setCompanyNameInput(e.target.value); // Update the immediate input state
                handleCompanyNameChange(e.target.value); // Handle the debounced update
            }}
            style={{ margin: 8 }}
        />




        <FormControl style={{ margin: 8, minWidth: 130 }}>
            <InputLabel id="researcher-select-label">Researcher</InputLabel>
            <Select
            labelId="researcher-select-label"
            id="researcher-select"
            value={filter.researcher}
            onChange={(e) => setFilter({ ...filter, researcher: e.target.value })}
            label="Researcher"
            >
            {users.map((user) => (
                <MenuItem key={user.userId} value={user.userId}>{user.username}</MenuItem>
            ))}
            </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FormControl style={{ margin: 8, minWidth: 160 }}>
                <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue) => {
                    setStartDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} style={{ margin: 8, width: 'auto' }} />}
                />
            </FormControl>
            <FormControl style={{ margin: 8, minWidth: 160 }}>
                <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newValue) => {
                    setEndDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} style={{ margin: 8, width: 'auto' }} />}
                />
            </FormControl>
            </LocalizationProvider>
        <br />
        <Button 
            variant="outlined" 
            onClick={clearFilters} 
            style={{ margin: 8 }}
            >
            Clear Filters
        </Button>
        <Dialog open={isEditSubsidiaryModalOpen} onClose={() => setIsEditSubsidiaryModalOpen(false)}>
                <DialogTitle>Subsidiary
                    <IconButton onClick={() => setIsEditSubsidiaryModalOpen(false)} style={{ position: 'absolute', right: '8px', top: '8px' }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {selectedParentId && <SubsidiaryEdit 
                        companyId={selectedCompanyId}
                        parentId={selectedParentId}
                        onClose={handleSubsidiaryEditClose} 
                    />}
                </DialogContent>
            </Dialog>
        {loading ? (
        <CircularProgress />
        ) : (
        <>
            <TableContainer>
            <Table>
                <TableHead>
                <TableRow>
                    <TableCell>
                        <TableSortLabel
                            active={sort.field === 'NID'}
                            direction={sort.field === 'NID' ? sort.order.toLowerCase() : 'asc'}
                            onClick={() => handleSortChange({ field: 'NID', order: sort.order === 'ASC' ? 'DESC' : 'ASC'})}
                        >
                            NID
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={sort.field === 'Title'}
                            direction={sort.field === 'Title' ? sort.order.toLowerCase() : 'asc'}
                            onClick={() => handleSortChange({ field: 'Title', order: sort.order === 'ASC' ? 'DESC' : 'ASC'})}
                        >
                            Title
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={sort.field === 'Action'}
                            direction={sort.field === 'Action' ? sort.order.toLowerCase() : 'asc'}
                            onClick={() => handleSortChange({ field: 'Action', order: sort.order === 'ASC' ? 'DESC' : 'ASC'})}
                        >
                            Action
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={sort.field === 'Researcher'}
                            direction={sort.field === 'Researcher' ? sort.order.toLowerCase() : 'asc'}
                            onClick={() => handleSortChange({ field: 'Researcher', order: sort.order === 'ASC' ? 'DESC' : 'ASC'})}
                        >
                            Researcher
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={sort.field === 'Timestamp'}
                            direction={sort.field === 'Timestamp' ? sort.order.toLowerCase() : 'asc'}
                            onClick={() => handleSortChange({ field: 'Timestamp', order: sort.order === 'ASC' ? 'DESC' : 'ASC'})}
                        >
                            Timestamp
                        </TableSortLabel>
                    </TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {activities.map((row) => (
                    <TableRow key={row.ID}>
                    <TableCell>
                    {row.NID > 9999999 ? (
                        row.NID
                    ) : row.Type === 'Parent' ? (
                        <Link to={`/company/${row.NID}/edit`}>{row.NID}</Link>
                    ) : (
                        <a href="javascript:void(0)" onClick={(e) => handleEditSubsidiaryClick(row.NID, row.ParentNID, e)}>
                        {row.NID}
                        </a>
                    )}
                    </TableCell>
                    <TableCell>{row.Type}: {row.Title}</TableCell>
                    <TableCell>{row.Action}</TableCell>
                    <TableCell>{users.find(user => user.userId === row.Researcher)?.username || 'Unknown'}</TableCell>
                    <TableCell>{new Date(row.Timestamp * 1000).toLocaleString()}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
            <TablePagination
            component="div"
            count={totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[500, 1000]}
            />
        </>
        )}
        <Button
            variant="contained"
            color="primary"
            onClick={handleDownload}
            style={{ margin: 8 }}
        >
            Download
        </Button>
    </Paper>
    );
};

export default ResearcherActivityReport;
