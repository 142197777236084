import React, { useEffect, useState, useCallback } from 'react';
import { TableSortLabel, Button, TextField, Select, MenuItem, InputLabel, FormControl, 
     CircularProgress, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { debounce } from 'lodash';
// Import your data service and user fetching service
import CompanyDataService from '../services/CompanyDataService'; // Adjust the path as needed

const LatestCompanies = () => {
    const [LatestCompanies, setLatestCompanies] = useState([]);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(1000);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [totalCount, setTotalCount] = useState(0);

    const [filter, setFilter] = useState({
        companyType: '',
      });
    const [sort, setSort] = useState({ field: 'LastUpdated', order: 'DESC' });
    const handleSortChange = (sortInfo) => {
        const field = sortInfo.field;
        const order = sortInfo.order;
        setSort({
            field,
            order,
        });
    };
    const clearFilters = () => {
        setFilter({
          companyType: ''
        });
        setStartDate(null);
        setEndDate(null);
        setPage(0);
        debouncedFetchLatestCompanies();
    };
          
    // Define your fetchLatestCompanies function
    const fetchLatestCompanies = async () => {
        try {
          const { companyType } = filter;
          const startCreatedDate = startDate ? startDate.getTime() / 1000 : '';
          const endCreatedDate = endDate ? endDate.getTime() / 1000 : '';
          const response = await CompanyDataService.getLatestCompanies(
            rowsPerPage, page, companyType, startCreatedDate, endCreatedDate, sort.field, sort.order
          );
          setLatestCompanies(response.data.results);
          setTotalCount(response.data.totalCount);
        } catch (error) {
          console.error("Failed to fetch LatestCompanies:", error);
        } finally {
          setLoading(false);
        }
      };
      

    // Debounce fetchLatestCompanies
    const debouncedFetchLatestCompanies = useCallback(debounce(fetchLatestCompanies, 500), [rowsPerPage, page, filter, sort, startDate, endDate]);

    // Use effect for re-fetching LatestCompanies when dependencies change
    useEffect(() => {
        if (!loading) {
            debouncedFetchLatestCompanies();
        }
      }, [debouncedFetchLatestCompanies, filter, sort]);
      
    useEffect(() => {
      fetchLatestCompanies();
    }, []); // Empty dependency array

    const handleChangePage = (event, newPage) => {
    setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    };
      
    const handleDownload = async () => {
          const { companyType } = filter;
          const startCreatedDate = startDate ? startDate.getTime() / 1000 : '';
          const endCreatedDate = endDate ? endDate.getTime() / 1000 : '';
          try {
            const downloadCsv = await CompanyDataService.getLatestCompanies(
              rowsPerPage, page, companyType, startCreatedDate, endCreatedDate, sort.field, sort.order, true
            );
            if (downloadCsv.data && downloadCsv.data.url) {
                // If the URL exists, create a link and trigger the download
                const link = document.createElement('a');
                link.href = downloadCsv.data.url;
                link.setAttribute('download', 'filename.csv'); // Optional: Specify a filename here
                document.body.appendChild(link);
                link.click();
                link.remove();
            } else {
                // If the URL does not exist, throw an error to be caught by the catch block
                throw new Error('Download URL is missing.');
            }
        } catch (error) {
            console.error("Failed to initiate download:", error);
            // Show an error message to the user. The method to do this depends on your UI framework
            // For example, if using React with a toast notification system, you might call:
            // toast.error("Failed to initiate download. Please try again.");
        }
    };
    
    

    return (
    <Paper>
        <FormControl style={{ margin: 8, minWidth: 160 }}>
            <InputLabel id="company-type-select-label">Company Type</InputLabel>
            <Select
            labelId="company-type-select-label"
            id="company-type-select"
            value={filter.companyType}
            // if e.target.value is empty, unset filter.companyType
            // otherwise, set to e.target.value
            onChange={(e) => setFilter({ ...filter, companyType: e.target.value || '' })}
            label="Company Type"
            >
            <MenuItem value={''}>All</MenuItem>
            <MenuItem value={'Parent'}>Parent</MenuItem>
            <MenuItem value={'Sub'}>Sub</MenuItem>
            </Select>
        </FormControl>


        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FormControl style={{ margin: 8, minWidth: 160 }}>
                <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue) => {
                    setStartDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} style={{ margin: 8, width: 'auto' }} />}
                />
            </FormControl>
            <FormControl style={{ margin: 8, minWidth: 160 }}>
                <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newValue) => {
                    setEndDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} style={{ margin: 8, width: 'auto' }} />}
                />
            </FormControl>
            </LocalizationProvider>
        <br />
        <Button 
            variant="outlined" 
            onClick={clearFilters} 
            style={{ margin: 8 }}
            >
            Clear Filters
        </Button>
        {loading ? (
        <CircularProgress />
        ) : (
        <>
            <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>NID</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sort.field === 'Company'}
                      direction={sort.field === 'Company' ? sort.order.toLowerCase() : 'asc'}
                      onClick={() => handleSortChange({ field: 'Company', order: sort.order === 'ASC' ? 'DESC' : 'ASC' })}
                    >
                      Company
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sort.field === 'CreatedDate'}
                      direction={sort.field === 'CreatedDate' ? sort.order.toLowerCase() : 'asc'}
                      onClick={() => handleSortChange({ field: 'CreatedDate', order: sort.order === 'ASC' ? 'DESC' : 'ASC' })}
                    >
                      Created Date
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {LatestCompanies.map((row) => (
                  <TableRow key={row.NID}>
                    <TableCell>{row.NID}</TableCell>
                    <TableCell>{row.ParentNID === 0 ? `Parent: ${row.Company}` : `Sub: ${row.Company} (Subsidiary of ${row.ParentCompany})`}</TableCell>
                    <TableCell>{new Date(row.CreatedDate * 1000).toLocaleString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            </TableContainer>
            <TablePagination
            component="div"
            count={totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[500, 1000]}
            />
        </>
        )}
        <Button
            variant="contained"
            color="primary"
            onClick={handleDownload}
            style={{ margin: 8 }}
        >
            Download
        </Button>
    </Paper>
    );
};

export default LatestCompanies;
