import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CompanyDataService from '../services/CompanyDataService';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Autocomplete, TextField, Select, MenuItem, Button, Box, FormControl, InputLabel, InputAdornment, FormHelperText, Accordion, AccordionSummary, AccordionDetails, TextareaAutosize, Typography, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { getUsers } from '../services/WordpressService'; // Import getUsers
import { AUTH_TOKEN } from '../helper';
import { listOfTitles } from '../helper';


const AssignResearcher = ({ companyId, onClose }) => {
    const id = companyId;
    const navigate = useNavigate();
    const isEditMode = id != null; // Check if it's edit mode or add mode
    const [company, setCompany] = useState({});
    const [formData, setFormData] = useState({}); // Form data state
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [users, setUsers] = useState([]); // State for users list
    const companyTypes = ['Private', 'Public', 'N/A', 'Government', 'Non-Profit', 'Educational', 'Other'];
    const careerListings = [
        { key: 0, value: 'No' },
        { key: 1, value: 'Yes' },
        { key: 2, value: 'N/A' }
    ];
    const [contacts, setContacts] = useState([
        { id: 'contact1', name: formData.Contact1 || '', title: formData.Contact1Title || '' },
        { id: 'contact2', name: formData.Contact2 || '', title: formData.Contact2Title || '' },
        { id: 'contact3', name: formData.Contact3 || '', title: formData.Contact3Title || '' }
    ]);
    const [naicsCodes, setNaicsCodes] = useState([]);
    const [selectedNaics, setSelectedNaics] = useState([]);
    const [selectedDivision, setSelectedDivision] = useState(null);


    useEffect(() => {
        if (isEditMode) {
            const fetchCompany = async () => {
                try {
                    setLoading(true);
                    const response = await CompanyDataService.get(id);
                    const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
                    const username = user ? user.user_display_name : '';
        
                    const companyData = {
                        ...response.data.company,
                        Username: username,
                        Status: 'Assigned'
                    };
        
                    setFormData(companyData);
                    setSelectedCountry(companyData.Country);
        
                    // Update contacts state with new company data
                    setContacts([
                        { id: 'contact1', name: companyData.Contact1 || '', title: companyData.Contact1Title || '' },
                        { id: 'contact2', name: companyData.Contact2 || '', title: companyData.Contact2Title || '' },
                        { id: 'contact3', name: companyData.Contact3 || '', title: companyData.Contact3Title || '' }
                    ]);
        
                    setLoading(false);
                } catch (err) {
                    setError('Failed to fetch company data');
                    setLoading(false);
                }
            };
            fetchCompany();
        } else {
                // Initialize empty form data for add mode
                setFormData({});
                setLoading(false);
        }
    
        const fetchUsers = async () => {
            try {
                const userList = await getUsers();
                setUsers(userList);
            } catch (error) {
                console.error("Failed to fetch users:", error);
            }
        };
    
        fetchUsers();
    }, [id]);    
    

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        let submissionSuccessful = false;
        const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
        
        console.debug('Form data:', formData);

        let researcherActivityData = {};
        researcherActivityData.NID = formData.DraftID ? formData.DraftID : formData.NID ? formData.NID : null;
        researcherActivityData.Title = formData.Company;
        researcherActivityData.Researcher = user.user_id? user.user_id : null;
        let researcherName = users.find(user => user.userId === formData.Researcher) ? users.find(user => user.userId === formData.Researcher).username : '';
        researcherActivityData.Action = "Assigned " + researcherName + " as Researcher";
        researcherActivityData.Type = formData.DraftParentID ? 'Sub' : formData.ParentNID ? 'Sub': 'Parent';
        researcherActivityData.ParentNID = formData.DraftParentID ? formData.DraftParentID : formData.ParentNID ? formData.ParentNID : null;
        // Prepare the updated form data
        const timestamp = new Date().toISOString().split('T')[0];
        let newHistoryEntry = `${timestamp} ${user.user_display_name} assigned ${researcherName} as Researcher\n`;

        const updatedFormData = {
            ...formData,
            History: `${newHistoryEntry}\n${formData.History || ''}` // Append the new entry before the existing history
        };
        
        await CompanyDataService.addResearcherActivity(researcherActivityData);
        try {
            if (!isEditMode) {
                const response = await CompanyDataService.create(updatedFormData);
                submissionSuccessful = response.status === 200; // Or your logic to determine success\
            } else {
                const response = await CompanyDataService.update(id, updatedFormData);
                submissionSuccessful = response.status === 200; // Or your logic to determine success
            }
            if (submissionSuccessful) {
                onClose(); // Close the modal on successful submission
                // Optionally navigate or trigger a refresh
            } else {
                // Handle the case where submission is not successful
                setError('Submission was not successful');
            }
        } catch (err) {
            setError('Failed to update company');
            // Handle error (show error message)
        }
    };

    // Update form data state
    const handleChange = (event) => {
        const { name, value } = event.target;
        // Update the state based on the input field's name
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    
    };
    
    // Conditional rendering based on loading/error states
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;


    // Form rendering
    return (
        <Box component="form" onSubmit={handleSubmit}>
            <h2>Assign Researcher</h2>
            <Accordion defaultExpanded>
                <AccordionDetails>
                    <input type="hidden" name="Username" value={formData.Username || ''} />
                    {/* Researchers Dropdown */}
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="researcher-label">Researcher</InputLabel>
                        <Select
                            labelId="researcher-label"
                            id="Researcher"
                            name="Researcher"
                            value={users.some(user => user.userId === formData.Researcher) ? formData.Researcher : ''}
                            label="Researcher"
                            onChange={(e) => setFormData({ ...formData, Researcher: e.target.value })}
                        >
                            {users.map(user => (
                                <MenuItem key={user.userId} value={user.userId}>
                                    {user.username}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {/* Notes Text Area */}
                    <TextareaAutosize
                        aria-label="Notes"
                        minRows={3}
                        placeholder="Add any notes here"
                        style={{ width: '100%', marginTop: '1rem' }}
                        name="Notes"
                        value={formData.Notes || ''}
                        onChange={handleChange}
                    />
                </AccordionDetails>
            </Accordion>
            <Button type="submit" color="primary">Submit</Button>
        </Box>
    );
};

export default AssignResearcher;