import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import { useTable, useSortBy, usePagination, useRowSelect, useResizeColumns, useFlexLayout } from 'react-table';
import CompanyDataService from '../services/CompanyDataService';
import { 
    Table, TextField, Slider, TableBody, TableCell, TableHead, TableRow,
    TableContainer, Paper, Box, Button, Typography, Select, MenuItem, Menu,
    Dialog, DialogTitle, DialogContent, DialogActions, IconButton, LinearProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getUsers } from '../services/WordpressService'; // Adjust the path as needed
import { format } from 'date-fns';
import { AUTH_TOKEN } from '../helper';
import { stateNameToCode } from '../helper';
import SubsidiaryEdit from './SubsidiaryEdit';
import BulkSubsidiaryEdit from './BulkSubsidiaryEdit';


const SubsidiariesList = (props) => {
    const {specialSubList} = props;
    const [totalCount, setTotalCount] = useState(0);
    const [data, setData] = useState([]);
    const [parentRealId, setParentRealId] = useState('notfound');
    const [loading, setLoading] = useState('Loading...');
    const [noDataMessage, setNoDataMessage] = useState('');
    const [pageCount, setPageCount] = useState(0);
    const fetchIdRef = React.useRef(0);
    const pageSize = 100; // Number of records per page
    const [users, setUsers] = useState([]);
    const [filterName, setFilterName] = useState('');
    const [appliedFilterName, setAppliedFilterName] = useState('');
    const [filterCountry, setFilterCountry] = useState('');
    const [appliedFilterCountry, setAppliedFilterCountry] = useState('');
    const [filterModeration, setFilterModeration] = useState('');
    const [appliedFilterModeration, setAppliedFilterModeration] = useState('');
    const location = useLocation(); // Get the current location
    const { id: nidFromParams } = useParams();
    const nid = nidFromParams || 'all'; // Set 'all' as default if nidFromParams is undefined or null
    const [isEditSubsidiaryModalOpen, setIsEditSubsidiaryModalOpen] = useState(false);
    const [selectedParentId, setSelectedParentId] = useState(null);
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);
    const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
    const [selectedCopyCompanyId, setSelectedCopyCompanyId] = useState(null);
    const [numberOfCopies, setNumberOfCopies] = useState(1);
    const [selectedSubsidiaries, setSelectedSubsidiaries] = useState([]);
    const [isBulkEditModalOpen, setIsBulkEditModalOpen] = useState(false);
    const [isBulkGeocodeModalOpen, setIsBulkGeocodeModalOpen] = useState(false);
    const [isBulkEditAllModalOpen, setIsBulkEditAllModalOpen] = useState(false);
    const [countries, setCountries] = useState([]);
    const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
    const userRoles = user?.user_roles ?? [];
    const [parentCompanyName, setParentCompanyName] = useState('');
    const [filterState, setFilterState] = useState('');
    const [appliedFilterState, setAppliedFilterState] = useState('');
    const [stateOptions, setStateOptions] = useState([]);
    const defaultHiddenColumns = [];  // Example default hidden columns
    const allPossibleColumns = [
        { Header: 'Name', accessor: 'Company' },
        { Header: 'Country', accessor: 'Country' },
        { Header: 'City', accessor: 'City' },
        { Header: 'St./Prov.', accessor: 'StateProvince' },
        { Header: 'Address 1', accessor: 'Address'},
        { Header: 'Postal Code', accessor: 'PostalCode' },
        { Header: 'Phone', accessor: 'Telephone' },
        { Header: 'Email', accessor: 'Email' },
        { Header: 'Website', accessor: 'Website' },
        { Header: 'Contact', accessor: 'Contact1' },
        { Header: 'Title', accessor: 'Contact1Title' },
        { Header: 'Sub Type', accessor: 'SubsidiaryType' },
        { Header: 'Status', accessor: 'Status' },
        { Header: 'Updated', accessor: 'LastUpdated'},
        { Header: 'Latitude', accessor: 'Latitude'},
        { Header: 'Longitude', accessor: 'Longitude'}
    ];
    
    const [hiddenColumns, setHiddenColumns] = useState(() => {
        const savedHiddenColumns = localStorage.getItem('hiddenColumns');
        console.debug('hidden columns')
        console.debug(savedHiddenColumns)
        return savedHiddenColumns ? JSON.parse(savedHiddenColumns) : defaultHiddenColumns;
    });

    const [isBulkZipModalOpen, setIsBulkZipModalOpen] = useState(false);
    const [processingZip, setProcessingZip] = useState(false);
    const [processedZipCount, setProcessedZipCount] = useState(0);
    const [totalToProcessZip, setTotalToProcessZip] = useState(0);
    const [zipProcessingResults, setZipProcessingResults] = useState([]);
    
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const userList = await getUsers();
                setUsers(userList);
            } catch (error) {
                console.error("Failed to fetch users:", error);
            }
        };
    
        fetchUsers();
    }, []);
  
    const handleEditSubsidiaryClick = (companyId, parentId, event) => {
        event.preventDefault();
        setSelectedCompanyId(companyId);
        setSelectedParentId(parentId);
        setIsEditSubsidiaryModalOpen(true);
    };

    const handleCopyClick = (companyId, event) => {
        event.preventDefault();
        setSelectedCopyCompanyId(companyId);
        setNumberOfCopies(1);
        setIsCopyModalOpen(true);
    };

    const handleSubsidiaryEditClose = () => {
        setIsEditSubsidiaryModalOpen(false);
        fetchData({
            pageSize: currentPageSize,
            pageIndex,
            sortBy,
            appliedFilterName,
            appliedFilterCountry,
            appliedFilterState,
            appliedFilterModeration,
        });
    };
    
    const handleBulkEditAllModalClose = () => {
        setIsBulkEditAllModalOpen(false);
        fetchData({
            pageSize: currentPageSize,
            pageIndex,
            sortBy,
            appliedFilterName,
            appliedFilterCountry,
            appliedFilterState,
            appliedFilterModeration,
        });
    }
    const handleBulkGeocodeModalClose = () => {
        setIsBulkGeocodeModalOpen(false);
        fetchData({
            pageSize: currentPageSize,
            pageIndex,
            sortBy,
            appliedFilterName,
            appliedFilterCountry,
            appliedFilterState,
            appliedFilterModeration,
        });
    }
    const handleBulkEditModalClose = () => {
        setIsBulkEditModalOpen(false);
        fetchData({
            pageSize: currentPageSize,
            pageIndex,
            sortBy,
            appliedFilterName,
            appliedFilterCountry,
            appliedFilterState,
            appliedFilterModeration,
        });
    }
    const handleCopyModalClose = () => {
        setIsCopyModalOpen(false);
        fetchData({
            pageSize: currentPageSize,
            pageIndex,
            sortBy,
            appliedFilterName,
            appliedFilterCountry,
            appliedFilterState,
            appliedFilterModeration,
        });
    };
    // Add new modal component
    const BulkZipModal = ({ open, onClose }) => {
        const usSubsidiaries = selectedSubsidiaries.filter(sub => sub.Country === 'United States');
        const nonUsCount = selectedSubsidiaries.length - usSubsidiaries.length;

        return (
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>
                    Bulk ZIP+4 Lookup
                    <IconButton onClick={onClose} style={{ position: 'absolute', right: '8px', top: '8px' }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {processingZip ? (
                        <>
                            <Typography>
                                Processing ZIP+4 lookups: {processedZipCount} of {totalToProcessZip}
                            </Typography>
                            <LinearProgress variant="determinate" value={(processedZipCount / totalToProcessZip) * 100} />
                        </>
                    ) : zipProcessingResults.length > 0 ? (
                        <>
                            <Typography variant="h6">Results:</Typography>
                            <ul>
                                {zipProcessingResults.map((result, index) => (
                                    <li key={index}>
                                        {result.company}: {result.success ? 
                                            `Updated to ${result.newZip}` : 
                                            `Failed - ${result.error}`}
                                    </li>
                                ))}
                            </ul>
                        </>
                    ) : usSubsidiaries.length === 0 ? (
                        <Typography>
                            No US subsidiaries selected. ZIP+4 lookup is only available for US addresses.
                            {nonUsCount > 0 && ` (${nonUsCount} non-US ${nonUsCount === 1 ? 'subsidiary' : 'subsidiaries'} selected)`}
                        </Typography>
                    ) : (
                        <Typography>
                            You are about to perform ZIP+4 lookup for {usSubsidiaries.length} US {usSubsidiaries.length === 1 ? 'subsidiary' : 'subsidiaries'}.
                            {nonUsCount > 0 && ` (${nonUsCount} non-US ${nonUsCount === 1 ? 'subsidiary' : 'subsidiaries'} will be skipped)`}
                            <br /><br />
                            This will create draft versions for any subsidiaries where a new ZIP+4 code is found. Do you want to proceed?
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Close</Button>
                    {!processingZip && zipProcessingResults.length === 0 && usSubsidiaries.length > 0 && (
                        <Button onClick={handleBulkZipSubmit} color="primary">
                            Process ZIP+4 Lookups
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        );
    };

    const handleBulkZipSubmit = async () => {
        setProcessingZip(true);
        setZipProcessingResults([]);
        setProcessedZipCount(0);
        
        const usSubsidiaries = selectedSubsidiaries.filter(sub => sub.Country === 'United States');
        setTotalToProcessZip(usSubsidiaries.length);
        
        const results = [];
        const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
        const timestamp = new Date().toISOString().split('T')[0];

        const normalizeZip = (zip) => {
            if (!zip) return '';
            // Remove all non-numeric characters except hyphen
            const cleaned = zip.replace(/[^\d-]/g, '');
            const parts = cleaned.split('-');
            // Ensure 5-digit ZIP is padded with leading zeros
            const base = parts[0].padStart(5, '0');
            // If there's a ZIP+4, ensure it's padded with leading zeros
            const plus4 = parts[1] ? `-${parts[1].padStart(4, '0')}` : '';
            return base + plus4;
        };
        
        for (let i = 0; i < usSubsidiaries.length; i++) {
            const subsidiary = usSubsidiaries[i];
            try {
                // Convert state name to two-letter code if needed
                const stateCode = stateNameToCode[subsidiary.StateProvince] || subsidiary.StateProvince;
                
                const address = {
                    street1: subsidiary.Address,
                    street2: subsidiary.Address2,
                    city: subsidiary.City,
                    state: stateCode,
                    zip: subsidiary.PostalCode ? subsidiary.PostalCode.split('-')[0] : ''
                };

                const response = await CompanyDataService.lookupZipPlus4(address);
                
                if (!response.data.error && response.data.address) {
                    const newZip = response.data.address.ZIPCode + 
                        (response.data.address.ZIPPlus4 ? '-' + response.data.address.ZIPPlus4 : '');
                    
                    const normalizedExisting = normalizeZip(subsidiary.PostalCode);
                    const normalizedNew = normalizeZip(newZip);

                    if (normalizedNew !== normalizedExisting) {
                        // Create history entry
                        const newHistoryEntry = `${timestamp} ${user.user_display_name} updated ZIP+4 via bulk operation from ${subsidiary.PostalCode || 'none'} to ${newZip}`;
                        
                        // Update the subsidiary with the new ZIP+4
                        const updatedSubsidiary = {
                            ...subsidiary,
                            PostalCode: newZip,
                            Status: 'Draft',
                            History: `${newHistoryEntry}\n${subsidiary.History || ''}`
                        };
                        
                        const subsidiaryId = subsidiary.DraftID || subsidiary.NID;
                        await CompanyDataService.update(subsidiaryId, updatedSubsidiary);
                        
                        results.push({
                            company: subsidiary.Company,
                            success: true,
                            newZip,
                            oldZip: subsidiary.PostalCode || 'none'
                        });
                    } else {
                        results.push({
                            company: subsidiary.Company,
                            success: false,
                            error: `ZIP code already correct (${subsidiary.PostalCode})`
                        });
                    }
                } else {
                    results.push({
                        company: subsidiary.Company,
                        success: false,
                        error: response.data.error || 'Invalid address'
                    });
                }
            } catch (error) {
                results.push({
                    company: subsidiary.Company,
                    success: false,
                    error: error.message
                });
            }
            
            setProcessedZipCount(i + 1);
        }
        
        setZipProcessingResults(results);
        setProcessingZip(false);
    };

    const handleDelete = async (nid, rowData) => {
        if (window.confirm("Are you sure you want to delete this company?")) {
            try {
                const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
                let researcherActivityData = {};
                researcherActivityData.NID = rowData.DraftID ? rowData.DraftID : rowData.NID;
                researcherActivityData.Title = rowData.Company;
                researcherActivityData.Researcher = user.user_id? user.user_id : null;
                researcherActivityData.Action = 'Deleted';
                researcherActivityData.Type = rowData.DraftParentID ? 'Sub' : rowData.ParentNID ? 'Sub': 'Parent';
                researcherActivityData.ParentNID = rowData.DraftParentID ? rowData.DraftParentID : rowData.ParentNID ? rowData.ParentNID : null;
                await CompanyDataService.addResearcherActivity(researcherActivityData);
                await CompanyDataService.delete(nid);
                // Refresh the list or page
                fetchData({
                    pageSize: currentPageSize,
                    pageIndex,
                    sortBy,
                    appliedFilterName,
                    appliedFilterCountry,
                    appliedFilterState,
                    appliedFilterModeration,
                });
            } catch (error) {
                // Handle error (e.g., show an error message)
                console.error("Error deleting company:", error);
            }
        }
    };
    const handleGeocode = async (nid) => {
        try {
            await CompanyDataService.geocode(nid);
            // // Refresh the list or page
            // fetchData({
            //     pageSize: currentPageSize,
            //     pageIndex,
            //     sortBy,
            //     appliedFilterName,
            //     appliedFilterCountry,
            //     appliedFilterState,
            //     appliedFilterModeration,
            // });
        } catch (error) {
            // Handle error (e.g., show an error message)
            console.error("Error geocoding company:", error);
        }
    };
    const handleBulkGeocodeSubmit = async () => {
        setIsBulkGeocodeModalOpen(false);
        try {
            for (let i = 0; i < selectedSubsidiaries.length; i++) {
                await CompanyDataService.geocode(selectedSubsidiaries[i].NID);
            }
            // Refresh the list or page
            fetchData({
                pageSize: currentPageSize,
                pageIndex,
                sortBy,
                appliedFilterName,
                appliedFilterCountry,
                appliedFilterState,
                appliedFilterModeration,
            });
        } catch (error) {
            // Handle error (e.g., show an error message)
            console.error("Error geocoding companies:", error);
        }
    };
    const handleCopySubmit = async () => {
        setIsCopyModalOpen(false);
        try {
            const response = await CompanyDataService.get(selectedCopyCompanyId);
            const originalData = response.data;
            for (let i = 1; i <= numberOfCopies; i++) {
                let copyData = {
                    ...originalData.company,
                    NID: undefined,
                    DraftID: undefined,
                    Status: 'Draft',
                    Company: `Copy ${i} of ${originalData.company.Company}`
                };
                await CompanyDataService.create(copyData);
            }
            fetchData({
                pageSize: currentPageSize,
                pageIndex,
                sortBy,
                appliedFilterName,
                appliedFilterCountry,
                appliedFilterState,
                appliedFilterModeration,
            });
            // Handle after successful copies (refresh list or show message)
        } catch (error) {
            // Handle error
        }
    };
    const handleCountryChange = async (e) => {
        const selectedCountry = e.target.value;
        setAppliedFilterCountry(selectedCountry);
        setFilterCountry(selectedCountry);
        setAppliedFilterState(''); // Reset filterState whenever filterCountry changes
    
        if (selectedCountry) {
            try {
                const response = await CompanyDataService.getAllStates(nid !== 'all' ? null : 'subs', nid);
                const statesForCountry = response.data[selectedCountry] || [];
                setStateOptions(statesForCountry);
            } catch (error) {
                console.error("Failed to fetch states:", error);
                setStateOptions([]); // Clear states on error
            }
        } else {
            setStateOptions([]); // Clear states when no country is selected
        }
    };
    const columns = React.useMemo(() => {
        const allColumns = [
            {
                id: 'selection',
                Header: ({ getToggleAllRowsSelectedProps }) => (
                    <div>
                        <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
                    </div>
                ),
                Cell: ({ row }) => (
                    <div>
                        <input type="checkbox" {...row.getToggleRowSelectedProps()} />
                    </div>
                ),
            },
            {
                Header: 'Done',
                accessor: 'markedDone',
                Cell: ({ row }) => {
                    return row.original.markedDone === 1 || row.original.markedDone === true
                        ? <div style={{ color: 'green' }}>✔</div> // This is your check mark
                        : null; // Or return a different element to indicate 'not done'
                },
            },
            {
                Header: 'Name',
                accessor: 'Company',
                Cell: ({ row }) => (
                    <a href="javascript:void(0)" onClick={(e) => handleEditSubsidiaryClick(row.original.DraftID || row.original.NID, row.original.DraftParentID || row.original.ParentNID, e)}>{row.original.Company}</a>
                ),
            },
            { Header: 'Country', accessor: 'Country' },
            { Header: 'City', accessor: 'City' },
            { Header: 'St./Prov.', accessor: 'StateProvince' },
            { Header: 'Address 1', accessor: 'Address' },
            { Header: 'Postal Code', accessor: 'PostalCode' },
            { Header: 'Phone', accessor: 'Telephone' },
            { Header: 'Email', accessor: 'Email' },
            { 
                Header: 'Website',
                accessor: 'Website',
                Cell: ({row}) => (
                    // This is a URL field. If it starts with http or https, make it a link.
                    // If it doesn't start with http or https, add https:// and make it a link.
                    <a href={row.original.Website.startsWith('http') ? row.original.Website : `https://${row.original.Website}`} target="_blank" rel="noreferrer">{row.original.Website}</a>
                )
            },
            { Header: 'Contact', accessor: 'Contact1' },
            { Header: 'Title', accessor: 'Contact1Title' },
            { Header: 'Sub Type', accessor: 'SubsidiaryType' },
            {
                Header: 'Operations',
                disableSortBy: true,
                id: 'options',
                Cell: ({ row }) => (
                    <>
                        <a href="javascript:void(0)" onClick={(e) => handleEditSubsidiaryClick(row.original.DraftID || row.original.NID, row.original.DraftParentID || row.original.ParentNID, e)}>Edit</a>
                        {" | "}
                        <a href="javascript:void(0)" onClick={(e) => handleCopyClick(row.original.DraftID || row.original.NID, e)}>Copy</a>
                        {" | "}
                        <Link to={`/company/${row.original.DraftID || row.original.NID}/moderate`}>Moderate</Link>
                        {userRoles.includes("administrator") && (
                            <>
                                {" | "}
                                <a href="javascript:void(0)" onClick={() => handleDelete(row.original.DraftID || row.original.NID, row.original)}>Delete</a>
                            </>
                        )}
                        {userRoles.includes("administrator") && nid === 'all' && !row.original.DraftID && (
                            <>
                                {" | "}
                                <a href="javascript:void(0)" onClick={() => handleGeocode(row.original.NID)}>Geocode</a>
                            </>
                        )}
                    </>
                ),
            },
            { Header: 'Status', accessor: 'Status' },
            {
                Header: 'Published',
                accessor: row => row.published, // Fetch data using a function
                id: 'published', // Unique ID for this column
                Cell: ({ value }) => {
                    if (value === 1) return 'Yes';
                    else return 'No';
                },
            },
            {
                Header: 'Updated',
                accessor: 'LastUpdated',
                Cell: ({ value }) => {
                    if (!value) return null; // If there's no value, return null or a placeholder
                    const date = new Date(value * 1000); // Convert the Unix timestamp to milliseconds and then to a Date object
                    return format(date, 'EEE, MM/dd/yyyy - h:mmaaa'); // Format the date
                },
            },
            {
                Header: 'Latitude',
                accessor: 'Latitude',
                Cell: ({ value }) => {
                    if (!value || value == '0') {
                        return '';
                    }
                    else {
                        return value.substring(0,11);
                    }
                }
            },
            {
                Header: 'Longitude',
                accessor: 'Longitude',
                Cell: ({ value }) => {
                    if (!value || value == '0') {
                        return '';
                    }
                    else {
                        return value.substring(0,11);
                    }
                }
            },
        ];
        console.debug(allColumns);
        console.debug(hiddenColumns);
        return allColumns.filter(column => !hiddenColumns.includes(column.accessor || column.id));
    }, [hiddenColumns]);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount: controlledPageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        selectedFlatRows,
        state: { pageIndex, pageSize: currentPageSize, sortBy },
    } = useTable(
        {
            columns, 
            data, 
            initialState: { 
                pageIndex: 0,
                pageSize,
                sortBy: [
                    {
                        id: 'LastUpdated', // This should match the accessor of the column
                        desc: true // false for ascending, true for descending
                    }
                ],
                hiddenColumns: hiddenColumns,  // Use hiddenColumns state
            },
            manualPagination: true, 
            pageCount, 
            autoResetPage: false, 
            manualSortBy: true
        },
        useSortBy,
        usePagination,
        useRowSelect,
    );

    const toggleColumn = (accessor) => {
        const newHiddenColumns = hiddenColumns.includes(accessor)
            ? hiddenColumns.filter(id => id !== accessor)
            : [...hiddenColumns, accessor];
        setHiddenColumns(newHiddenColumns);
        localStorage.setItem('hiddenColumns', JSON.stringify(newHiddenColumns));
    };
        

    // Debounced fetchData function
    const debouncedFetchData = useCallback(_.debounce(fetchData, 1000), [users]);

    useEffect(() => {
        const savedFilters = localStorage.getItem('SubsidiaryListFilters');
        if (savedFilters) {
            const subsidiaryFilters = JSON.parse(savedFilters);
            if (subsidiaryFilters[nid]) {
                const { appliedFilterName, appliedFilterCountry, appliedFilterState, appliedFilterModeration } = subsidiaryFilters[nid];
                setAppliedFilterName(appliedFilterName || '');
                setAppliedFilterCountry(appliedFilterCountry || '');
                setAppliedFilterState(appliedFilterState || '');
                setAppliedFilterModeration(appliedFilterModeration || '');
            }
        }
    }, [nid]);

    useEffect(() => {
        const savedFilters = localStorage.getItem('SubsidiaryListFilters');
        const subsidiaryFilters = savedFilters ? JSON.parse(savedFilters) : {};
    
        subsidiaryFilters[nid] = {
            appliedFilterName,
            appliedFilterCountry,
            appliedFilterState,
            appliedFilterModeration,
        };
    
        localStorage.setItem('SubsidiaryListFilters', JSON.stringify(subsidiaryFilters));
    }, [appliedFilterName, appliedFilterCountry, appliedFilterState, appliedFilterModeration, nid]);
    
    const applyFilters = () => {
        // Update the applied filters state, which will trigger the useEffect to fetch data
        setAppliedFilterName(filterName);
        setAppliedFilterCountry(filterCountry);
        setAppliedFilterState(filterState);
        setAppliedFilterModeration(filterModeration);
      };

    useEffect(() => {
        // Trigger fetch data with filters
        const params = {
            pageIndex,
            pageSize: currentPageSize,
            sortBy,
            appliedFilterName,
            appliedFilterCountry,
            appliedFilterState,
            appliedFilterModeration,
        };
    
        debouncedFetchData(params);
    }, [debouncedFetchData, pageIndex, currentPageSize, sortBy, appliedFilterName, appliedFilterCountry, appliedFilterState, appliedFilterModeration]);
    
    useEffect(() => {
        // Cleanup function to reset pagination
        return () => {
            gotoPage(0); // Navigate to the first page
        };
    }, [gotoPage]);

    useEffect(() => {
        const selectedRows = selectedFlatRows.map(row => row.original);
        setSelectedSubsidiaries(selectedRows);
    }, [selectedFlatRows, page, debouncedFetchData]);

    useEffect(() => {
        if (loading === false && page.length > 0) {
            const fetchCountries = async () => {
                try {
                    let response;
                    if (nid !== 'all') {
                        response = await CompanyDataService.getAllCountries('subs', nid); 
                    } else {
                        response = await CompanyDataService.getAllCountries('subs', null, appliedFilterName); 
                    }
                    setCountries(response.data);
                } catch (error) {
                    console.error("Failed to fetch countries:", error);
                }
            };
            fetchCountries();
        }
    }, [appliedFilterName, page, nid, loading]);

    const fetchStates = async () => {
        if (appliedFilterCountry) {
            try {
                const response = await CompanyDataService.getAllStates(nid !== 'all' ? null : 'subs', nid, appliedFilterName);
                const statesForCountry = response.data[appliedFilterCountry] || [];
                setStateOptions(statesForCountry);
            } catch (error) {
                console.error("Failed to fetch states:", error);
                setStateOptions([]); // Clear states on error
            }
        } else {
            setStateOptions([]); // Clear states when no country is selected
        }
    };
    
    useEffect(() => {
        if (loading === false && page.length > 0) {
            fetchStates();
        }
    }, [appliedFilterCountry, page, loading, nid, appliedFilterName]); // Dependency on filterCountry and nid
    
    

    const resetFilters = () => {
        setFilterName('');
        setAppliedFilterName('');
        setFilterCountry('');
        setAppliedFilterCountry('');
        setFilterState('');
        setAppliedFilterState('');
        setFilterModeration('');
        setAppliedFilterModeration('');
    
        // Trigger data fetch with reset filters
        debouncedFetchData({
            pageIndex: 0,
            pageSize: currentPageSize,
            sortBy,
            appliedFilterName: '',
            appliedFilterCountry: '',
            appliedFilterState: '',
            appliedFilterModeration: '',
        });
    
        // Reset localStorage for the current nid
        const savedFilters = localStorage.getItem('SubsidiaryListFilters');
        const subsidiaryFilters = savedFilters ? JSON.parse(savedFilters) : {};
        subsidiaryFilters[nid] = {
            appliedFilterName: '',
            appliedFilterCountry: '',
            appliedFilterState: '',
            appliedFilterModeration: '',
        };
        localStorage.setItem('SubsidiaryListFilters', JSON.stringify(subsidiaryFilters));
    };
    
    useEffect(() => {
        const fetchParentCompany = async () => {
            if (nid !== 'all') {
                try {
                    const parentResponse = await CompanyDataService.get(nid);
                    setParentCompanyName(parentResponse.data.company.Company);
                } catch (error) {
                    console.error("Failed to fetch parent company:", error);
                }
            }
        };
    
        fetchParentCompany();
    }, [nid]);
    // Define mapResearcherIdToName function here to ensure it uses the latest users data
    const mapResearcherIdToName = (researcherId, usersArray) => {
        const user = usersArray.find(user => user.userId === researcherId);
        return user ? user.username : '';
    };

    async function fetchData(params) {
        const { pageSize, pageIndex, sortBy, appliedFilterName, appliedFilterCountry, appliedFilterModeration, appliedFilterState } = params;
    
        const fetchId = ++fetchIdRef.current;
        setLoading(true);
    
        if (fetchId === fetchIdRef.current) {
            try {
                // Proceed only if users data is available
                if (users && users.length > 0) {
                    const sortField = sortBy.length ? sortBy[0].id : '';
                    const sortOrder = sortBy.length && sortBy[0].desc ? 'desc' : 'asc';
                    const queryParams = new URLSearchParams();
                    if (appliedFilterName) queryParams.append('filterName', appliedFilterName);
                    if (appliedFilterCountry) queryParams.append('filterCountry', appliedFilterCountry);
                    if (appliedFilterCountry && appliedFilterState) queryParams.append('filterState', appliedFilterState);
                    if (appliedFilterModeration) queryParams.append('filterModeration', appliedFilterModeration);
                    let response;
                    if (nid === 'all') {
                        if (specialSubList === 'subs-no-parents') {
                            response = await CompanyDataService.getAllSubsidiaries("-1", pageSize, pageIndex, sortField, sortOrder, queryParams.toString());
                        }
                        else {
                            response = await CompanyDataService.getAllSubsidiaries(nid, pageSize, pageIndex, sortField, sortOrder, queryParams.toString());
                        }
                    } else {
                        response = await CompanyDataService.getAllSubsidiaries(nid, pageSize, pageIndex, sortField, sortOrder, queryParams.toString());
                    }
                    console.debug('results length', response.data.results.length);
                    if (response.data.results.length === 0) {
                        setNoDataMessage('No Subsidiaries Found.');
                    }
                    const subsidiaries = response.data.results;
                    setParentRealId(response.data.parentEditID);
                    setTotalCount(response.data.totalCount);

                    // Map researcher ID to name
                    const subsidiariesWithResearcherName = subsidiaries.map(subsidiary => ({
                        ...subsidiary,
                        Researcher: mapResearcherIdToName(subsidiary.Researcher, users)
                    }));
    
                    setData(subsidiariesWithResearcherName);
                    setPageCount(Math.ceil(response.data.totalCount / pageSize));
                } else {
                    // Optional: Fetch users here if they're not available
                    // ...fetchUsers logic...
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                // Handle error appropriately
            }
            setLoading(false);
        }
    }
    const CompaniesTableContainer = styled(TableContainer)({
        overflowX: 'visible', // Enable vertical scrolling
        width: 'fit-content', // Adjust as needed
    });

    const StickyTableHead = styled(TableHead)({
        position: 'sticky',
        top: 0,
        zIndex: 1, // Ensure the sticky header appears above other elements
        backgroundColor: 'white', // Optional: Customize the background color of the sticky header
    });
    // Render the UI for your table
    return (
        <div>
            {specialSubList === 'subs-no-parents' && <h4>Subsidiaries with No Parent</h4>}
            {parentCompanyName && <h4>{totalCount} Subsidiaries for <Link to={`/company/${(parentRealId !== 'notfound' ? parentRealId : nid)}/edit`}>{parentCompanyName}</Link></h4>}
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Toggle Columns</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {allPossibleColumns.map((column) => (
                        <div key={column.accessor || column.id} style={{ marginRight: 20 }}>
                            <input
                                type="checkbox"
                                checked={!hiddenColumns.includes(column.accessor || column.id)}
                                onChange={() => toggleColumn(column.accessor || column.id)}
                                style={{ marginRight: 5 }}
                            />
                            {column.Header}
                        </div>
                    ))}
                </AccordionDetails>
            </Accordion>
            <Dialog open={isEditSubsidiaryModalOpen} onClose={() => setIsEditSubsidiaryModalOpen(false)}>
                <DialogTitle>Subsidiary
                    <IconButton onClick={() => setIsEditSubsidiaryModalOpen(false)} style={{ position: 'absolute', right: '8px', top: '8px' }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {selectedParentId && <SubsidiaryEdit 
                        companyId={selectedCompanyId}
                        parentId={selectedParentId}
                        onClose={handleSubsidiaryEditClose} 
                    />}
                </DialogContent>
            </Dialog>
            <Dialog open={isCopyModalOpen} onClose={handleCopyModalClose}>
                <DialogTitle>Copy Company
                    <IconButton onClick={handleCopyModalClose} style={{ position: 'absolute', right: '8px', top: '8px' }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent><br />
                    <TextField
                        label="Number of Copies to Create"
                        type="number"
                        InputProps={{ inputProps: { min: 1 } }}
                        value={numberOfCopies}
                        onChange={e => setNumberOfCopies(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCopyModalClose}>Cancel</Button>
                    <Button onClick={handleCopySubmit}>Copy</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={isBulkGeocodeModalOpen} onClose={handleBulkGeocodeModalClose}>
                <DialogTitle>Bulk Edit Selected Subsidiaries
                    <IconButton onClick={handleBulkGeocodeModalClose} style={{ position: 'absolute', right: '8px', top: '8px' }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent><br />
                    You are about to geocode {selectedSubsidiaries.length} subsidiaries. Are you sure you want to proceed?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleBulkGeocodeModalClose}>Cancel</Button>
                    <Button onClick={handleBulkGeocodeSubmit}>Geocode</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={isBulkEditModalOpen} onClose={handleBulkEditModalClose}>
                <DialogTitle>Bulk Edit Selected Subsidiaries
                    <IconButton onClick={handleBulkEditModalClose} style={{ position: 'absolute', right: '8px', top: '8px' }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <BulkSubsidiaryEdit
                        selectedSubsidiaries={selectedSubsidiaries}
                        parentId={nid !== 'all' ? nid : null}
                        onClose={handleBulkEditModalClose}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={isBulkEditAllModalOpen} onClose={handleBulkEditAllModalClose}>
                <DialogTitle>Bulk Edit All Subsidiaries
                    <IconButton onClick={handleBulkEditAllModalClose} style={{ position: 'absolute', right: '8px', top: '8px' }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <BulkSubsidiaryEdit
                        selectedSubsidiaries='all'
                        parentId={nid !== 'all' ? nid : null}
                        onClose={handleBulkEditAllModalClose}
                    />
                </DialogContent>
            </Dialog>

            <div>

                {/* Filters */}
                <label>Name:</label>
                <TextField label="Name" value={filterName} onChange={e => setFilterName(e.target.value)} />

                <label>Country:</label>
                {/* Country Filter */}
                <Select value={filterCountry} onChange={handleCountryChange}>
                    <MenuItem value="">Any</MenuItem>
                    {countries.map(country => (
                        <MenuItem key={country} value={country}>{country}</MenuItem>
                    ))}
                </Select>
                {/* State Filter */}
                {stateOptions.length > 0 && (
                    <>
                        <label>State:</label>
                        <Select value={filterState} onChange={e => setFilterState(e.target.value)}>
                            <MenuItem value="">Any</MenuItem>
                            {stateOptions.map(state => (
                                <MenuItem key={state} value={state}>{state}</MenuItem>
                            ))}
                        </Select>
                    </>
                )}
                <label>Moderation State:</label>
                {/* Moderation State Filter */}
                <Select value={filterModeration} onChange={e => setFilterModeration(e.target.value)}>
                    <MenuItem value="">Any</MenuItem>
                    <MenuItem value="Draft">Draft</MenuItem>
                    <MenuItem value="Published">Published</MenuItem>
                    <MenuItem value="Suggested for Removal">Suggested for Removal</MenuItem>
                </Select>

            </div>
            <button onClick={applyFilters}>Apply Filters</button><br />
            <Button onClick={resetFilters}>Reset Filters</Button>
            {/* Show bulk edit button only if nid is not 'all' */}
            {nid !== 'all' && (<Button onClick={() => setIsBulkEditModalOpen(true)}>Bulk Edit Selected Subsidiaries</Button>)}
            {nid !== 'all' && (<Button onClick={() => setIsBulkEditAllModalOpen(true)}>Bulk Edit All Subsidiaries</Button>)}
            {nid !== 'all' && userRoles.includes("administrator") && (
                <Button onClick={() => setIsBulkZipModalOpen(true)}>
                    Bulk Add ZIP+4 Selected
                </Button>
            )}
            <BulkZipModal 
                open={isBulkZipModalOpen}
                onClose={() => {
                    setIsBulkZipModalOpen(false);
                    setProcessingZip(false);
                    setZipProcessingResults([]);
                    fetchData({
                        pageSize: currentPageSize,
                        pageIndex,
                        sortBy,
                        appliedFilterName,
                        appliedFilterCountry,
                        appliedFilterState,
                        appliedFilterModeration,
                    });
                }}
            />
            {userRoles.includes("administrator") && (<Button onClick={() => setIsBulkGeocodeModalOpen(true)}>Bulk Geocode Selected Subsidiaries</Button>)}
            {/* if nid is not 'all', show an 'add subsidiary' link */}
            {nid !== 'all' && (
                <>
                    <a href="javascript:void(0)" onClick={(e) => handleEditSubsidiaryClick(null, nid, e)}>Add New Subsidiary</a>
                    {" | "}
                    <Link to={`/company/${nid}/bulk-import-subsidiaries`}>Bulk Import Subsidiaries</Link>
                </>
            )}
            <CompaniesTableContainer component={Paper}>
                <Table {...getTableProps()} key={hiddenColumns.join(",")}>
                    <StickyTableHead>
                        {headerGroups.map(headerGroup => (
                            <TableRow {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <TableCell {...column.getHeaderProps(column.getSortByToggleProps())} 
                                    style={{ 
                                        textDecoration: column.canSort ? 'underline' : 'none',
                                        cursor: column.canSort ? 'pointer' : 'auto',
                                    }}>
                                    {column.render('Header')}
                                    {/* Add sorting indicators if needed */}
                                    <span>
                                        {column.isSorted ? (column.isSortedDesc ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />) : null}
                                    </span>
                                </TableCell>
                            ))}
                            </TableRow>
                        ))}
                    </StickyTableHead>


                    <TableBody {...getTableBodyProps()}>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={columns.length} align="center">
                                    Loading...
                                </TableCell>
                            </TableRow>
                        ) : page.length > 0 ? (
                            page.map(row => {
                                prepareRow(row);
                                const isSuggestedForRemoval = row.original.suggestForRemoval === 1;
                                const isMarkedDone = row.original.markedDone === 1;
                                // Set isMoved constant to true if row.original.ParentNID is not equal to nid, make sure to match type
                                // Additionally, do not set isMoved to true if nid is 'all'
                                var isMoved = false;
                                if (nid !== 'all') {
                                    if (row.original.ParentNID === null || row.original.ParentNID === 0 || row.original.ParentNID === '0') {
                                        // If ParentNID is null, 0, or '0', check DraftParentID
                                        isMoved = row.original.DraftParentID !== null && row.original.DraftParentID != nid;
                                    } else {
                                        // If ParentNID has a value other than null, 0, or '0'
                                        isMoved = row.original.ParentNID != nid;
                                    }
                                }
                                return (
                                    <TableRow 
                                        {...row.getRowProps()} 
                                        style={{ 
                                            backgroundColor: isSuggestedForRemoval ? 'lightpink' : isMarkedDone ? 'lightgreen' : isMoved ? 'mediumorchid' : row.original.Status == 'Draft' ? row.original.Company.startsWith('Copy ') ? '' : 'lightgreen' : '', // Conditional styling
                                            // If row.original.Company starts with 'Copy (numeric value) of', set the background color to none.
                                        }}
                                        // add a class to the row if isMoved is true
                                        className={isMoved ? 'moved' : ''}
                                    >
                                        {row.cells.map(cell => (
                                            <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                                        ))}
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length} align="center">
                                    {noDataMessage}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {/* Pagination controls will go here */}
            </CompaniesTableContainer>
            <Box p={2} display="flex" justifyContent="center" alignItems="center">
                <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</Button>
                <Button onClick={() => previousPage()} disabled={!canPreviousPage}>{'<'}</Button>
                <Button onClick={() => nextPage()} disabled={!canNextPage}>{'>'}</Button>
                <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</Button>
                <Typography>
                    Page {pageIndex + 1} of {pageOptions.length}
                </Typography>
                <Select
                    value={currentPageSize}
                    onChange={e => setPageSize(Number(e.target.value))}
                    displayEmpty
                >
                    {[100, 250, 500, 1000, 2000].map(size => (
                        <MenuItem key={size} value={size}>
                            Show {size}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
        </div>
    );
};

export default SubsidiariesList;
